.wizardCorrespondenceFilesContainer {
    width: 100%;
    .headerContainer {
        padding-bottom: 16px;
        border-bottom: solid #f3f2f1 1px;
    }
    .supportingDocSection {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
    }
}
