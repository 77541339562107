@import "../../styles/variables";
.wizardCorrespondenceDetailsContainer {
    width: 100%;
}

.headerContainer {
    padding-bottom: 16px;
}

.supportingDocSection {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
}

.batchRecordsSection {
    width: 100%;
    background-color: $grey20;
    padding: 30px 0;
}

.checkboxTopGap {
    margin-top: 10px;
}

.batchRecordsContainer {
    width: 100%;
    padding: 0 5%;
    .batchRecordContainer {
        // padding: 5px 10% 160px 10%;
        background-color: $white;
        display: flex;
        flex-direction: column;
        padding: 15px 40px 35px 40px;
        box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.108);
        border-radius: 2px;
        margin-bottom: 15px;
        .batchRecordHeaderContainer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            .headerInfoContainer {
                display: flex;
                flex-direction: column;
                margin-right: 15px;
                max-width: 50%;
                div {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 24px;
                    color: $typePrimary;
                }
            }
            .actionButtonsContainer {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                @media (max-width: 640px) {
                    min-width: auto;
                }
                @media (max-width: 400px) {
                    .viewMessageButton {
                        width: 110px;
                    }   
                }
            }
        }
        .batchRecordInformationContainer {
            display: flex;
            flex-direction: row;
            min-height: 150px;
            padding: 15px 0;
            .columnsTypeContainer {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 50%;
                .columnType {
                    font-weight: 600;
                    font-size: 14px;
                    color: $typePrimary;
                }
            }
            .columnsValuesContainer {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .columnValue {
                    font-size: 14px;
                    line-height: 20px;
                    color: $grey130;
                }
            }
        }
        .batchRecordFieldsContainer {
            display: flex;
            flex-direction: column;
            width: 50%;
            min-height: 350px;
            justify-content: space-between;
            .saveBatchRecordInformation {
                max-width: 100px;
            }
        }
        .correspondentsContainer {
            display: flex;
            flex-direction: row;
            width: 100%;
            margin-top: 20px;
            justify-content: space-between;
            flex-wrap: wrap;
            @media (max-width: 640px) {
                flex-direction: column;
            }
            .columnFields {
                width: 45%;
                .correspondentItemHeaderContainer {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin-bottom: 15px;
                    .fromText {
                        font-weight: 600;
                        font-size: 14px;
                        color: $typePrimary;
                    }
                    button {
                        height: 20px !important;
                    }
                }
                .actionsCorrespondentButtonsContainer {
                    display: flex;
                    flex-direction: row;
                    margin-top: 25px;
                    .saveNewCorrespodButton {
                        margin-right: 15px;
                    }
                }
                .correspondentInfoContainer {
                    display: flex;
                    flex-direction: column;
                    .viewContactInfoContainer {
                        margin-bottom: 10px;
                        .contactInfoWithActionsButtonsContainer {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            .usetInformationContainer {
                                width: 100%;
                            }
                        }
                    }
                    .viewContactInfoContainer:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .hideOrShowButton {
            max-width: 150px;
            margin-top: 15px;
            span[class^="ms-Button-label label-"] {
                font-size: 14px;
                color: $grey190;
            }
        }
    }
    .batchRecordContainer:last-child {
        margin-bottom: 0;
    }
    .noCorrespondenceContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100px;
        font-size: 14px;
        color: $grey130;
    }
}

.userDisplayName {
    font-size: 14px;
    color: $grey160;
}

.userAdditionalInfo {
    font-size: 12px;
    color: $grey130;
    line-height: 16px;
}

.organizationContainer {
    margin-top: 15px;
}

.userContact {
    .emailContainer {
        margin-top: 15px;
        display: flex;
        flex-direction: row;
    }
    div {
        font-size: 14px;
        color: $grey160;
    }
    .email {
        font-size: 14px;
        color: $themePrimary;
        margin-left: 3px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.multiline {
    white-space: pre-line;
}

.fieldsCorrespondentContainer {
    display: flex;
    flex-direction: column;
    .addNewCorrespondentContainer {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-top: 20px;
        .orText {
            font-weight: 600;
            font-size: 14px;
            color: $typePrimary;
            margin-top: 5px;
            margin-right: 15px;
        }
        .addNewCorrespondentButton {
            height: 37px;
        }
    }
    .saveNewCorrespondentButton {
        margin-top: 40px;
        height: 37px;
    }
}

.contactTypeChoiceGroupContainer {
    margin-bottom: 20px;
}

.orgContactItemContainer {
    display: flex;
    flex-direction: column;
    .orgContactLabelField {
        font-weight: 600;
        font-size: 14px;
        color: $grey160;
        margin-bottom: 10px;
    }
}

.organisationSubFormContainer {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid $grey90;
    background-color: $themeLighter;
    border-radius: 3px;
    .orgSubFormButtonContainer {
        display: flex;
        flex-direction: row;
        margin-top: 15px;
        .submitButton {
            margin-right: 15px;
        }
        .orgSubFormCancelButton {
            max-width: 85px;
        }
    }
}

.fieldOrgContainer {
    margin-bottom: 15px;
}

@media (max-width: 640px) {
    .batchRecordFieldsContainer {
        width: 100% !important;
    }
    .columnFields {
        width: 100% !important;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid $grey20;
    }
    .columnFields:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
    }
}