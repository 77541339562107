@import "../../../styles/variables";

.contactPickerContainer {
    width: 100%;
    position: relative;
    .contactPickerLabel {
        width: 100%;
        padding-bottom: 0;
    }
    .contactPicker {
        margin-top: 10px;
    }
    .requiredFlag {
        color: $statusRequaredIcon;
        position: absolute;
        top: 0;
        right: 5px;
    }
}

.coinUser{
    :global(.ms-Persona-initials) {
        background-color: rgb(20, 130, 5);
    }
}

.coinOrg{
    :global(.ms-Persona-initials) {
        background-color: rgb(164, 38, 44);
    }
}

.coinAad{
    :global(.ms-Persona-initials) {
        background-color: rgb(164, 151, 38);
    }
}
:global(.ms-Suggestions-item) .personaWrapper{
    width: 240px;
}


