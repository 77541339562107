@import "../../styles/variables";
@import "./ManageRecordCommon.module";
.manageRecordRelatedRecordsContainer {
    font-style: normal;
    font-weight: 400;
    .sectionHeader {
        display: flex;
        gap: 10px;
        padding: 48px 0 44px;
        h2 {
            font-weight: 600;
            line-height: 28px;
            margin: 0px;
            min-width: 160px;
        }
        button {
            height: 28px;
        }
        .btnsContainer {
            width: 100%;
            display: flex;
            justify-content: space-between;
            .deleteBtn {
                .text,
                .spinner {
                    margin: 0px 4px;
                }
            }
        }
    }
    .table {
        width: 100%;
        padding-bottom: 150px;
        .recordTypeColumnIcon {
            font-size: 17px;
            margin-left: 3px;
        }
        .recordFieldColumn {
            font-size: 14px;
        }
        // columns
        .recordIdColumn {
            cursor: pointer;
        }
        .iconColumnContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            .iconColumnIcon {
                font-size: 16px;
                text-align: center;
                margin-bottom: 3px;
            }
            .iconColumnText {
                font-weight: 600;
                font-size: 10px;
                line-height: 12px;
            }
        }
        .recordIdLink {
            text-decoration: none;
            outline: none;
        }
        .actionsCell {
            display: flex;
            justify-content: flex-end;
            .deleteBtn {
                height: initial;
                .text,
                .spinner {
                    margin: 0px 4px;
                }
            }
        }
    }
}