@import "../../styles/variables";

.recordsColumnsPanel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .descriptionTextPanel {
        font-size: 14px;
        line-height: 20px;
        color: $grey160;
        padding: 35px 0 80px 0;
    }
    .checkboxsContainer {
        display: flex;
        flex-direction: column;
        .checkBoxItem {
            margin: 10px 0;
            div[class^="ms-Checkbox-checkbox checkbox-"] {
                border: 1px solid $themeDarkAlt;
            }
        }
        .disabledCheckBoxItem {
            div[class^="ms-Checkbox-checkbox checkbox-"] {
                border: 1px solid $grey60;
            }
        }
    }
    .separator {
        padding: 30px 0;
    }
}
.footer{
    padding-bottom: 34px;
    .actionsButtonsContainer {
        display: flex;
        flex-direction: row;
        
        button {
            margin: 0 5px;
        }
    }
    .errorMessage{
        margin-bottom: 20px;
    }
}

