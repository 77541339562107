.wizardSummaryContainer {
    width: 100%;
}
.bodyContainer {
    width: 100%;
    margin-top: 50px;
    .signatureContainer {
        border-top: #edebe9 solid 2px;
        padding: 20px 0;
        margin: 10px 0;
    }

    .templateName .documentName {
        color: #605e5c;
        text-decoration: none;
        font-size: 14px;
        font-weight: normal;
    }

    .noselection {
        color: #d83b01;
    }
}
.sectionHeading {
    width: 100%;
    h2 {
        color: #201f1e;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        margin: 0;
        padding: 40px 0 20px;
    }
}
.footerContainer {
    margin: 20px 0;
    .primaryContainer {
        border-left: #0052c2 solid 4px;
        background-color: #deecf9;
        padding: 20px 30px 30px;
        .featureText {
            font-weight: 700;
            margin-bottom: 20px;
        }
        .checkboxContainer {
            margin-bottom: 30px;
        }
        .subTextContainer {
            span {
                line-height: 20px;
            }
        }
    }
}
