@import "../../styles/variables";
@import "../manageRecordWrapper/ManageRecordCommon.module";
.fieldsContainer {
    width: 100%;
    .spinner{
        margin-bottom: 5rem;
    }
}

.accessPermissionsContainer {
    width: 100%;
    border-top: 1px solid $grey30;
    padding: 37px 0 0 0;
    .accessHeader {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 13px;
    }
    .accessDescription {
        font-size: 14px;
        line-height: 20px;
    }
    .buttonContainer {
        width: 100%;
        margin: 16px 0;
        .buttonStyling {
            display: inline-block;
            margin-right: 16px;
        }
    }
}

.expandedAccessContainer {
    .expandedHeaderDescription {
        margin: 16px 0 0;
    }
    .expandedHeaderDescriptionStrong {
        margin: 16px 0 0;
        font-weight: 500;
    }
    .expandedCheckboxContainer {
        margin: 56px 0;
        .checkboxDescription {
            margin-top: 10px;
            font-weight: 600;
            height: 22px;
        }
    }
    .expandedNominationContainer {
        width: 100%;
        margin-top: 17px;
        .peoplePicker {
            width: 45%;
            max-width: 380px;
            margin-right: 40px;
            display: inline-block;
            vertical-align: top;
            @media (max-width: 640px) {
                width: 100%;
                margin-bottom: 0;
            }
        }
        .addButton {
            display: inline-block;
            vertical-align: bottom;
            @media (max-width: 640px) {
                margin-top: 16px;
            }
        }
        .listContainer {
            width: 100%;
        }
        .usersCardContainer {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 32px 0px;
            .userCardItemContainer {
                display: flex;
                flex-direction: column;
                margin-bottom: 16px;
                padding: 16px;
                background: $white;
                box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
                border-radius: 2px;
                max-width: 380px;
                min-height: 80px;
                .fieldUserInfo {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 10px;
                    .userInfoText {
                        font-size: 12px;
                        color: $grey130;
                    }
                    .userName {
                        color: $themePrimary;
                    }
                }
                .fieldUserInfo:last-child {
                    margin-bottom: 0;
                }
                .btnsArea {
                    .deleteBtn {
                        padding: 0px;
                        margin-left: -6px;
                        font-weight: 400;
                    }
                }
            }
            .userCardItemContainer:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.btnsContainer {
    margin: 10px 0;
    display: flex;
    gap: 10px;
    .updateBtn {
        .text,
        .spinner {
            margin: 0px 4px;
        }
    }
}

.commentsDescrContainer {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    .commentsDescrHeader {
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 15px;
    }
    .commentsDescrText {
        max-width: 380px;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 20px;
    }
}