.exportSchemaPage {
    width: 100%;
    margin-top: 30px;
    .pageContainer {
        width: 80%;
        margin: auto;
        .pageHeader{
            font-weight: 600;
            font-size: 28px;
            line-height: 36px;
            margin-bottom: 48px;
        }
        .headerText {
            margin-bottom: 15px;
            font-weight: 600;
        }

        .bodySmallSection{
            padding-bottom: 18px;
        }

        .headerSubText {
            margin-bottom: 15px;
            font-weight: 400;
        }
        .buttonContainer {
            padding-top: 16px;
            padding-bottom: 16px;         
        }
        .primaryButtonStyling {
            margin-right: 16px;
        }
    
        .buttonSpinner {
            margin-left: 4px;
        }
    }
    .bodyContainer {
        padding-bottom: 36px;
        border-bottom: solid #f3f2f1 1px;
        .button {
            margin: 10px 20px 0 0;
        }
        .heading {
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
        }
        .subHeading {
            font-size: 14px;
            font-weight: 600;
            margin: 24px 0 16px;
        }
        .warnings{
            margin-bottom: 20px;
        }
    }
    .iconBlue {
        color:#0052c2;
    }
}
