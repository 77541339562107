@import "../../../styles/variables";
.datePickerControl {
    content: "";
    clear: both;
    display: table;
    width: 100%;
    > div {
        clear: both;
    }
    .datePicker {
        width: 100%;
    }
    .disabledDatePickerField {
        div[class^="ms-TextField-fieldGroup fieldGroup-"] {
            border: none;
        }
    }
    .timePickerContainer {
        width: 50%;
    }
    .fullContainer {
        width: 100%;
        display: flex;
        gap: 10px;
    }
    .description {
        color: #666;
        font-weight: 400;
        font-size: 11px;
    }
    .invalidRangeMessage {
        color: $statusErrorTypeOrIcon;
        font-size: 12px;
        padding: 4px 0px;
        span {
            padding-right: 4px;
        }
    }
    .timeFieldGap {
        margin-top: 15px;
    }
}

.calloutContainer {
    button[class*="ms-DatePicker-goToday"] {
        right: 10px;
    }
}