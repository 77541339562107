.documentGenerationPage {
    width: 100%;
    margin-top: 30px;
    .pageContainer {
        width: 80%;
        margin: auto;
        padding-bottom: 170px;
        .pageHeader {
            font-weight: 600;
            font-size: 28px;
            line-height: 36px;
            margin-bottom: 48px;
        }
        .headerText {
            margin-bottom: 15px;
            font-weight: 600;
        }
        .actions {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            .searchBoxContainer {
                min-width: 380px;
            }
            .mobileSearchBoxContainer {
                min-width: auto;
                width: 100%;
                padding-bottom: 20px;
            }
            .filter {
                align-self: flex-end;
            }
        }
        .recordIdColumn {
            cursor: pointer;
        }
        .refiner {
            display: block;
        }
        .searchResultContainer {
            .searchResultText {
                font-size: 20px;
                font-weight: 600;
                line-height: 28px;
            }
        }
        .searchResultItem {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .errorMessage {
                max-width: 183px;
                margin: 5px 0px;
            }
        }
    }
    .mobilePageContainer {
        width: 100%;
        padding-bottom: 50px;
        .pageHeader {
            padding: 0px 20px;
        }
        .actions {
            padding: 0px 20px;
        }
        .searchResultContainer {
            .searchResultText {
                padding: 0px 20px 20px;
            }
        }
        .mobileSearchResult {
            padding: 0px 20px 16px;
            background-color: #faf9f8;
            .mobileFilter {
                padding: 16px 0px;
            }
            .refiner {
                padding-bottom: 16px;
            }
        }
        .searchResultItem {
            background-color: white;
            padding: 16px;
            margin-bottom: 16px;
            gap: 24px;
            box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
            border-radius: 2px;
        }
    }

    .containerStyle {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        float: left;
        left: 20px;
        top: 50%;
        padding: 20px
    }

    .circleStyle {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: bold;
        color: white;
        position: relative;
        z-index: 1;
        transition: background-color 0.3s ease
    }

    .lineStyle {
        width: 2px;
        height: 30px;
        background-color: #ccc;
        margin: -1px auto;
        z-index: 0,
    }

    .spinner {
        margin-top: 100px;
    }

    .generatedDocContainer {
        margin-top: 40px;
        padding: 40px;
        border: 1px solid #ccc;
    }

    .wizardContainer {
        
        border: 1px solid lightgrey;
        min-height: 300px;
        .wizardStepContainer {
            padding: 20px;
            margin-left: 80px;

            * {
                clear: none;
            }

            .inlineCheckbox {
                float: left;
                margin-top: 2px;
            }
        }
    }

    .wizardButtons {
        margin-top: 20px;
        .nextButton {
            float: right;
        }
    }

}
