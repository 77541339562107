.refinerActiveFilters {
    display: block;

    .clearAllBtn {
        :global {
            .ms-Button-label {
                line-height: 20px;
                font-size: 14px;
                color: #201f1e;
            }
            .ms-Button-icon {
                font-size: 14px;
                color: #0052c2;
            }
        }
    }
    .refinersList {
        display: flex;
        flex-flow: wrap;
        gap: 8px;
        .removeRefinerBtn {
            background: #f3f2f1;
            height: 26px;

            :global {
                .ms-Button-flexContainer {
                    flex-direction: row-reverse;
                }
                .ms-Button-label {
                    line-height: 20px;
                    font-size: 14px;
                    color: #201f1e;
                }
                .ms-Button-icon {
                    font-size: 12px;
                    color: #605e5c;
                }
            }
        }
        .overdueBgr {
            background: #d83b01;
        }
        .nowBgr {
            background: #107c10;
        }
        .thisWeekBgr {
            background: #2b88d8;
        }
        .nextWeekBgr {
            background: #106ebe;
        }
        .nextMonthBgr {
            background: #005a9e;
        }
        .overdueBgr, .nowBgr, .thisWeekBgr, .nextWeekBgr, .nextMonthBgr {
            :global{
                .ms-Button-label {
                    color: white;
                }
                .ms-Button-icon {
                    color: white;
                }
            } 
        }
    }
    .top {
        display: block;
    }
    .bot {
        display: block;
    }
}
