.tabsController {
    width: 100%;
    margin-top: 40px;
    @media (max-width: 640px) {
        padding: 0;
        margin-top: 0;
    }
    .tabsContainer {
        width: 100%;
        padding: 0 10%;
        .tab {
            color: #201f1e;
            display: inline-block;
            font-size: 18px;
            line-height: 24px;
            padding: 10px 0;
            cursor: pointer;
            margin: 0px 20px 0px 10px;
        }
        .activeTab {
            border-bottom: 4px solid #0052c2;
            font-weight: 600;
        }
    }
    .childrenContainer {
        min-height: 400px;
        width: 100%;
        margin-top: 20px;
        padding: 0 10%;
        // background: #faf9f8;
        .children {
            border-top: #edebe9 solid 1px;
        }
    }
}