.booleanRefiner {
    .checkboxList {
        padding: 10px 0px;
        .listItem {
            padding: 12px 0px;
            .checkbox {
                display: block;
            }
        }
    }
}
