.searchPage {
    width: 100%;
    margin-top: 30px;
    .pageContainer {
        width: 80%;
        margin: auto;
        padding-bottom: 170px;
        .pageHeader{
            font-weight: 600;
            font-size: 28px;
            line-height: 36px;
            margin-bottom: 48px;
        }
        .headerText {
            margin-bottom: 15px;
            font-weight: 600;
        }
        .actions{
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            .searchBoxContainer{
                min-width: 380px;
            }
            .mobileSearchBoxContainer{
                min-width: auto;
                width: 100%;
                padding-bottom: 20px;
            }
            .filter{
                align-self: flex-end;
            }
        }
        .recordIdColumn {
            cursor: pointer;
        }
        .refiner{
            display: block;
        }
        .searchResultContainer{
            .searchResultText{
                font-size: 20px;
                font-weight: 600;
                line-height: 28px;
            }
        }
        .searchResultItem{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            
            .errorMessage{
                max-width: 183px;
                margin: 5px 0px;
            }
        }
    }
    .mobilePageContainer{
        width: 100%;
        padding-bottom: 50px;
        .pageHeader{
            padding: 0px 20px;
        }
        .actions{
            padding: 0px 20px;
        }
        .searchResultContainer{
            .searchResultText{
                padding: 0px 20px 20px;
            }
        }
        .mobileSearchResult{
            padding: 0px 20px 16px;
            background-color: #faf9f8;
            .mobileFilter{
                padding: 16px 0px;
            }
            .refiner{
                padding-bottom: 16px;
            }
        }
        .searchResultItem{
            background-color: white;
            padding: 16px;
            margin-bottom: 16px;
            gap: 24px;
            box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
            border-radius: 2px;
        }
    }
}