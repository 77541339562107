.hoverPersonCardContainer {
    display: inline;
}
.personHoverCardItem {
    min-width: 270px;
    display: flex;
    flex-direction: column;
    .divider {
        background: #edebe9;
        width: 100%;
        height: 1px;
    }
    .personInfoContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px;
    }
    .personCommunicationInfoContainer {
        display: flex;
        flex-direction: column;
        padding: 12px;
        .personCommunication {
            display: flex;
            flex-direction: row;
            i {
                margin-right: 15px;
                margin-top: 3px;
            }
            a {
                text-decoration: none;
                color: #0052c2;
            }
        }
    }
}
