@import "../../../styles/variables";
.refinerPanelContainer {
    .btn {
        display: inline-block;
    }

    // .tableHeaderTopContainer {
    //     width: 100%;
    //     padding: 10px 0 16px;
    //     border-bottom: 1px solid #edebe9;

    //     .containerLeft {
    //         width: 50%;
    //         display: inline-block;
    //         .buttonLeft {
    //             margin-right: 16px;
    //             display: inline-block;
    //         }
    //         .searchField {
    //             display: inline-block;
    //             max-width: 700px;
    //             vertical-align: top;
    //             margin-right: 16px;
    //         }
    //         .resultsText {
    //             display: inline-block;
    //             color: #605e5c;
    //         }

    //         @media (max-width: 640px) {
    //             .buttonLeft {
    //                 display: block;
    //                 margin-bottom: 10px;
    //             }
    //             .searchField {
    //                 display: block;
    //             }
    //         }
    //     }
    //     .containerRight {
    //         width: 50%;
    //         display: inline-block;
    //         text-align: right;
    //         .resultsText {
    //             color: #605e5c;
    //             font-size: 14px;
    //             display: inline-block;
    //         }
    //         .iconRight {
    //             margin-left: 16px;
    //         }
    //     }
    //     @media (max-width: 1171px) {
    //         .containerLeft,
    //         .containerRight {
    //             width: 100%;
    //         }
    //         .containerRight {
    //             margin-top: 16px;
    //             text-align: left;
    //             .iconRight {
    //                 margin: 0 16px 0 0;
    //             }
    //         }
    //     }
    // }
    // .tableHeaderBottomContainer {
    //     width: 100%;
    //     padding: 10px 0 16px;
    //     .itemSelectionText {
    //         color: #605e5c;
    //         font-size: 14px;
    //         display: inline-block;
    //         padding: 4px;
    //     }
    //     .editColumnsText {
    //         .pencilIcon {
    //             margin-right: 10px;
    //         }
    //         color: #201f1e;
    //         text-align: right;
    //         padding: 4px;
    //         cursor: pointer;
    //     }
    // }
}

.refinerPanel {
    .refinerContainer {
        display: block;
        height: 100%;
        .refinerAccordion {
            .refiner {
                display: block;
            }
            :global {
                .head,
                .head.open {
                    border: 0px;
                    .title.open {
                        font-weight: 400;
                    }
                    .icon {
                        width: 30px;
                        .chevron {
                            font-size: 12px;
                            color: #201f1e;
                        }
                    }
                }
                .body.expnded {
                    padding: 12px 30px;
                }
            }
        }
        .header {
            display: block;
            padding-top: 16px;
        }
        .bodyContainer {
            overflow-y: auto;
        }
        .additionalBox {
            overflow: hidden;
            height: 80px;
        }
        .footerContainer {
            position: absolute;
            bottom: 0;
            width: 100%;
            background-color: $white;
            .footer {
                display: flex;
                padding: 16px 24px 50px;
                gap: 16px;
            }
        }
    }
    :global {
        .ms-Panel-contentInner {
            height: 100%; 
        }
        .ms-Panel-scrollableContent {
            height: 100%;
        }
        .ms-Panel-content {
            height: 100%;
            padding: 0px;
        }
    }
}
.separator {
    padding: 0px;
    line-height: 1px;
    height: 1px;
}
.shimmer {
    gap: 8px;
}
