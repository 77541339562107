.overviewRecommendContainer {
    padding: 0 10%;

    @media (max-width: 640px) {
        padding: 15px 5% 40px 5%;
    }
    .errorInfoContainer {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        h1 {
            font-size: 18px;
            font-weight: 500;
        }
        p {
            font-size: 14px;
            color: #8a8786;
        }
    }
}

.formContainer {
    width: 100%;
    .textField {
        // div[class^="fieldGroup-"] {
        //     min-height: 100px !important;
        //     textarea {
        //         min-height: 100px !important;
        //     }
        // }
    }

    .recommendHeader {
        font-size: 18px;
        font-weight: 500;
    }
    .addRecommendButton {
        margin-bottom: 30px;
    }
    .recommendSubtitle {
        font-weight: 400;
    }
    .submitButtonsContainer {
        display: flex;
        flex-direction: row;
        margin-bottom: 40px;
    }

    .recommendationsContainer {
        min-height: 75px;
        background: #f3f2f1;
        margin-bottom: 20px;
        padding: 3px 15px;
        .recommendationsItems {
            background-color: #ffffff;
            margin: 30px 15px;
            display: flex;
            flex-direction: column;
            .recommendationItem {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #f3f2f1;
                padding: 0 15px;
                .recommendationLeftColumn {   
                    max-width: calc(100% - 500px);
                    min-width: 200px;
                    padding: 10px 0;
                    @media (max-width: 1100px) {
                        width: 100%;
                        max-width: 100%;
                    }
                    .recommendationLeftColumnContainer {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        width: 100%;
                        .recommendItemTextInfo {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            .recommendType {
                                color: #0052c2;
                                font-weight: 500;
                                font-size: 14px;
                                margin-right: 40px;
                                max-width: 25px;
                            }
                            .mobileRecommendType {
                                font-size: 12px;
                                margin-bottom: 10px;
                                margin-right: 0;
                            }
                            .recommendDescription {
                                text-overflow: ellipsis;
                                font-size: 14px;
                                color: #201f1e;                                
                                margin-left: 30px;
                                word-break: break-word;
                            }
                            .mobileRecommendDescription {
                                font-size: 12px;
                                white-space: normal;
                                margin-left: 0px;
                            }
                        }
                        .mobileRecommendItemTextInfo {
                            flex-direction: column;
                            width: 100%;
                            align-items: flex-start;
                        }
                        .reorderContainer {
                            max-width: 30px;
                            display: flex;
                            flex-direction: column;
                            flex-flow: column;
                            padding: 0px 10px;
                            flex-basis: 10%;
                            margin: 0 20px 5px 0;
                            .reorder {
                                height: 10px;
                            }
                            .chevron {
                                font-size: 10px;
                                height: 10px;
                            }
                        }
                        .mobileReorderContainer {
                            display: none;
                        }
                    }
                }
                .mobileRecommendationLeftColumn{
                    max-width: 100%;
                }
                .recommendationRightColumn {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    max-width: 540px;
                    flex-wrap: wrap;
                    gap: 10px;
                    padding: 10px 0;
                    justify-content: space-between;
                    width: 58%;
                    @media (max-width: 1100px) {
                        width: 100%;
                        max-width: 100%;
                        justify-content: space-between;
                    }
                    .checkboxRecommendContainer {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        padding: 0px 8px;
                        span {
                            white-space: nowrap;
                        };
                        *{
                            margin: 2px 0;
                            margin-right: 4px;
                            width: auto;
                        }
                    }
                    .mobileCheckboxRecommendContainer {
                        flex-direction: column;
                        // height: 90px;
                        flex-wrap: nowrap;
                    }

                    .editRecommendButton {
                    }
                    .mobileEditRecommendButton {
                        margin-top: 5px;
                    }
                }
                .mobileRecommendationRightColumn {
                    flex-direction: column;
                    align-items: flex-start;
                }
            }
            .recommendationItem:last-child {
                border-bottom: none;
            }
            .mobileRecommendationItem {
                padding: 15px;
                min-height: 230px;
                box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
                border-radius: 2px;
                margin-bottom: 15px;
                background: #ffffff;
                flex-direction: column;
                align-items: flex-start;
            }
        }
        .mobileRecommendationsItems {
            background-color: transparent;
        }
        .noHasRecommendInfo {
            display: flex;
            flex-direction: row;
            color: #8a8786;
            font-size: 14px;
            i {
                margin-right: 5px;
                margin-top: 3px;
                color: #212121;
            }
        }
    }
    .mobileRecommendationsContainer {
        background-color: #faf9f8;
    }
}
