.dashboard {
    width: 100%;
}
.bgGrey {
    background-color: #f3f2f1;
}
.bgWhite {
    background-color: white;
}
.dashboardSection {
    min-height: 100px;
    padding: 15px 0;
    @media (max-width: 640px) {
        padding: 0 0 15px;
    }
}
.sectionPadding {
    padding-left: 10%;
    padding-right: 10%;
}
.dashboardActionButtons {
    border-bottom: 1px solid #edebe9;
    padding: 24px 10% 16px;
    .buttonContainer {
        display: inline-block;
        margin-right: 16px;
        .buttonStyling {
            margin-top: 2px;
        }
    }
    .buttonContainerStandard {
        vertical-align: top;
    }
}
