.spinnerStyling {
    display: inline-block;
    vertical-align: middle;
}
.fileLink {
    display: inline-block;
    color: #106ebe;
    font-weight: 500;
    padding: 10px 7px 0 5px;
}
.fileLinkHidden {
    display: none;
}
