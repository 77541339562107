.userRefiner {
    .searchBoxContainer {
        padding-bottom: 10px;
        .searchbox {
            display: block;
        }
    }
    .checkboxList {
        padding: 10px 0px;
        .listItem {
            display: flex;
            align-items: center;
            padding: 12px 0px;
            gap: 8px;
            .checkbox {
                display: block;
            }
            .persona {
                width: 100%;
                cursor: pointer;
            }
        }
    }
    .seeAllBtn {
        padding: 10px 0px;
        .btn {
            display: block;
        }
    }
}
.body {
    display: block;
    height: 100%;
    overflow-y: auto;
    padding: 20px 30px;
    background-color: #faf9f8;
}
.footer {
    display: flex;
    padding: 16px 24px;
    gap: 16px;
}
.separator {
    padding: 0px;
    line-height: 1px;
    height: 1px;
}
