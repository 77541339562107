.wizardKeyRolesContainer {
    width: 100%;
}
.formContainer {
    width: 100%;
    // background-color: #faf9f8;
    position: relative;
    padding: 10px 0;
    .formContainerBackground {
        // To Do
        z-index: -1;
        position: absolute;
        background-color: #faf9f8;
        height: calc(100% + 200px);
        // width: 200%;
        max-width: 90%;
        left: -25%;
        top: -10px;
    }
    .roleSection {
        border-bottom: 2px solid #edebe9;
        padding: 48px 0 44px;

        @media (max-width: 640px) {
            padding: 32px 0 30px;
        }

        &:last-child {
            border-bottom: none;
        }
        .sectionHeading {
            width: 100%;
            margin: 0 0 32px;
            h2 {
                color: #323130;
                font-weight: 600;
                font-size: 20px;
                line-height: 28px;
                margin: 0;
            }
        }
        .sectionColumn {
            width: 50%;
            display: inline-block;
            vertical-align: top;
        }
        @media (max-width: 640px) {
            .sectionColumn {
                width: 100%;
            }
            .sectionColumn:empty {
                display: none;
            }
        }
    }
}
