.wizardResponseTemplateContainer {
    width: 100%;

    .sections {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        padding-bottom: 40px;
        border-bottom: solid #f3f2f1 1px;
    }
    .header {
        padding-bottom: 16px;
        margin: 0;
        font-weight: 500;
        font-size: 18px;
    }
    .subHeader {
        font-size: 14px;
        line-height: 20px;
    }
    .descriptions {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        padding-bottom: 9px;
    }
    .templateContainer {
        background-color: #f3f2f1;
        margin-top: 24px;
        padding: 16px 0;
        .selectContainer {
            margin-left: 15px;
            @media (max-width: 640px) {
               width: calc(100% - 30px);
            }
        }
    }
    .templateContainerNewForm {
        background-color: #f3f2f1;
        margin-top: 24px;
        padding: 16px 15px;
        .selectContainer{
            padding-bottom: 40px;
        }
    }
    .buttonStyling {
        margin-left: 15px;
    }
    .supportingDocSection {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        padding-top: 32px;
        padding-bottom: 40px;
        border-bottom: solid #f3f2f1 1px;
    }
}
