@import "../manageRecordWrapper/ManageRecordCommon.module";
.notificationsContainer {
    font-style: normal;
    font-weight: 400;
    .myNotificationsContainer {
        padding-bottom: 48px;
        border-bottom: solid #f3f2f1 1px;
    }
    .otherNotificationsContainer {
        padding-top: 48px;
        // padding-bottom: 64px;
        // border-bottom: solid #f3f2f1 1px;
    }
    .overrideCheckbox {
        margin: 30px 0;
    }
    .sectionHeader {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
    }
    .sectionContent {
        margin-top: 24px;
    }
    .sectionContent2 {
        margin-top: 46px;
    }
    .subHeader {
        font-size: 16px;
        font-weight: 600;
    }
    .primaryButtonStyling {
        margin-right: 16px;
    }
    .buttonContainer {
        padding-top: 42px;
        padding-bottom: 72px;
        // border-bottom: solid #f3f2f1 2px;
    }
    .availableFrequencies {
        width: 100%;
        margin-top: 24px;
        max-width: 680px;
    }
    .manualFrequencyOptions:first-child {
        margin-top: 15px;
    }
    .manualFrequencyOptions {
        margin-bottom: 8px;
        margin-left: 25px;
        max-width: 680px;
    }
    .notifyOthersDescription,
    .decisionDescription,
    .recommendationDescription {
        margin-top: 16px;
        max-width: 619px;
    }
    .peoplePickerContainer {
        line-height: 24px;
    }
    .peoplePicker {
        max-width: 380px;
        width: 50%;
        display: inline-block;
        margin-right: 40px;
        margin-top: 16px;
        @media (max-width: 1023px) {
            min-width: 150px;
        }
    }
    .selectedPeople {
        display: flex;
        background-color: white;
        padding-left: 12px;
        padding-right: 9px;
        height: 42px;
        line-height: 42px;
        border-bottom: solid 1px #f3f2f1;
    }
    .selectedPeople:last-child {
        border-style: none;
    }
    .noPeopleMsg {
        line-height: 24px;
        display: flex;
        font-size: 12px;
    }
    .noPeopleIcon {
        margin-right: 4px;
    }
    .personName {
        margin-left: 9px;
        color: #0052C2;
        min-width: 482px;
    }
    .editIcon {
        color: #0052C2;
    }
    .selectedRecommendationHeader,
    .selectedDecisionHeader {
        margin-top: 16px;
        margin-bottom: 8px;
        padding: 5px 0px;
        font-weight: 600;
    }
    .selectedRecommendations,
    .selectedDecisions {
        max-width: 689px;
        background-color: #F3F2F1;
        border-radius: 2px;
        min-height: 79px;
        padding: 16px 16px;
    }
    .removeButtonStyling {
        border-style: none;
        font-size: 14px;
        font-weight: 400;
        margin-top: 5px;
    }
}