.fileUploadContainer {
    width: 100%;
    font-size: 14px;
    font-weight: normal;
    // padding-top: 24px;

    .messageBar {
        margin-bottom: 15px;
    }
    .filedragdrop {
        margin-top: 24px;
        margin-bottom: 24px;
        background-color: #f3f2f1;
        min-height: 100px;
    }

    .uploadedFilesContainer {
        margin-top: 24px;
        background-color: #f3f2f1;
    }

    .filebrowser {
        display: none;
    }
}

.selectFileSPContextMenuItem {
    cursor: pointer;
    
    .selectFileSPIcon {
        font-size: 20px;
        color: #0052c2;
    }
}
