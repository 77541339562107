.controlContainer {
    // margin-bottom: 32px;

    // clearfix
    content: "";
    clear: both;
    // display: table;
    width: 100%;
    max-width: 380px;
    min-width: 90px;
}

.controlContainerWide {
    max-width: 760px;
}
