@import "./ManageRecordCommon.module.scss";
.manageRecordKeyRolesContainer {
    .header {
        display: flex;
        width: 100%;
        padding-top: 20px;
    }
    .footer {
        display: flex;
        width: 100%;
        padding-bottom: 80px;
    }
    .updateBtn {
        .text,
        .spinner {
            margin: 0px 4px;
        }
    }
}