.select {
    width: 100%;
    .multiItem {
        padding: 5px;
    }
}

.hideDisabledOption {
    div[class*="is-disabled"]{
        display: none;
    }
}

.labelContainer {
    padding: 5px 0;
    font-weight: 400;

    &.required {
        label:after {
            content: "*";
            color: red;
        }
    }
}

.description {
    color: #666;
    font-weight: 400;
    font-size: 11px;
}
