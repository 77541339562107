.container {
    max-width: 90%;
    padding-top: 10px;
    padding-bottom: 20px;
    width: 500px;
    border-top: 4px solid #0052c2;
    @media (max-width: 640px) {
        overflow: scroll;
        height: 100%;
        -webkit-overflow-scrolling: touch;
    }
    div[class^="ms-Modal-scrollableContent scrollableContent-"] {
        height: 100%;
    }
    .sectionPadding {
        padding: 0px 20px;
    }
    .header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10px;
        .title {
            display: inline-block;
            // Removed ellipsis due to mobile bug: https://dev.azure.com/dpcdadev01/ABC%20Project/_workitems/edit/2488/
            // white-space: nowrap;
            // overflow: hidden;
            // text-overflow: ellipsis;
            margin: 0px;
            margin-right: 15px;
            font-size: 20px;
            font-weight: 600;
        }
        .closeBtn {
            position: absolute;
            right: 10px;
            color: rgb(102, 102, 102);
            &:hover {
                color: rgb(51, 51, 51);
            }
        }
    }
    .body {
        margin: 40px 0px;
        @media (max-width: 640px) {
            margin: 30px 0;
        }
    }
    .footer {
        padding-top: 10px;
        .submitButton {
            margin-right: 10px;
        }
    }
}
