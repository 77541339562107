@import "../../../styles/variables";

.linkContainer {
    padding: 1px 5px 0px 5px;
    display: flex;
    align-items: baseline;
}

.docLink {
    font-size: 14px;
    line-height: 20px;
    text-decoration: none;
    color: #201f1e;
    margin-right: 10px;
}

.docContainer {
    display: flex;
    text-decoration: none;
    height: 14px;
    line-height: 14px;
    padding: 16px 0px;
    @media (max-width: 640px) {
       height: auto;
       line-height: auto;
       padding: 0 0 8px;
    }
}

.iconContainer {
    height: 18px;
    width: 24px;
}

.docIcon {
    // display: inline-block;
    margin-right: 4px;
    color: $themePrimary;
    font-size: 16px;
    @media (max-width: 640px) {
        margin-top: 1px;
    }
}

.docName {
    // display: inline-block;
    font-size: 14px;
    line-height: 20px;
    color: $themePrimary;
    word-wrap: break-word;
}
.fileNotAvailableMessage {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
}
