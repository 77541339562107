@import "../../../styles/variables";

.cardsContainer {
    background: #faf9f8;
    width: 100%;
    .card {
        padding: 16px;
        margin: 0 0 16px;
        background: #ffffff;
        box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
        border-radius: 2px;
        .pdfButton {
            border-color: $inputBorder;
            i {
                color: $themePrimary;
            }
        }
        .linkStyling {
            text-decoration: none;
        }
    }
}
