@import "../../../styles/variables";

.userListContainer {
    width: 100%;
}
.userInfo {
    font-size: 14px;
}
.userDisplayNameInfo {
    color: $themeDark;
}
.deleteBtn {
    height: 18px;
    font-weight: 400;
    font-size: 14px;
    i {
        font-size: 16px;
        color: $themeDark;
    }
}