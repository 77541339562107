.recordsTableContainer {
    width: 100%;
    @media (min-width: 641px) {
        padding: 0 10px;
    }
    @media (max-width: 640px) {
        padding-top: 32px;
    }
    .header {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        @media (max-width: 640px) {
            gap: 10px;
        }
        .top {
            display: flex;
            gap: 16px;
            @media (max-width: 640px) {}
            .section {
                width: 50%;
                display: flex;
                gap: 16px;
                @media (max-width: 768px) {
                    gap: 11px;
                }
            }
            .left {
                align-content: flex-start;
                justify-content: flex-start;
                @media (max-width: 640px) {
                    flex-wrap: wrap;
                }
            }
            .right {
                align-content: flex-end;
                justify-content: flex-end;
                @media (max-width: 640px) {
                    flex-wrap: wrap;
                    align-content: flex-start;
                    justify-content: flex-start;
                }
            }
            .actionsButtons {
                @media (max-width: 640px) {
                    margin-bottom: 5px;
                }
            }
            .exportBtn {
                min-width: 225px;
                i {
                    color: #0052c2;
                }
            }
            @media (max-width: 640px) {
                .exportBtn:last-child {
                    margin-bottom: 0;
                }
            }
            .searchBox {
                min-width: 250px;
                i {
                    color: #0052c2;
                }
            }
            .resultsCount {
                line-height: 32px;
            }
            @media (max-width: 1171px) {
                flex-direction: column;
                .section {
                    width: 100%;
                }
            }
        }
        .mid {
            display: block;
        }
        .bot {
            .section {
                display: flex;
                justify-content: space-between;
                @media (max-width: 640px) {
                    margin-bottom: 10px;
                }
                .mobileResultsCount {
                    line-height: 42px;
                }
            }
            .noItemsSelectedMsg {
                font-size: 12px;
                color: #605e5c;
                line-height: 40px;
            }
            .editColumnBtn {
                height: 40px;
                i {
                    font-size: 12px;
                    color: #323130;
                }
                span {
                    font-size: 12px;
                }
            }
        }
    }
    .table {
        width: 100%;
        min-height: 400px;
    }
}

.separator {
    padding: 0px;
    line-height: 1px;
    height: 1px;
}

.exportPrevent {
    font-weight: 600;
    margin-top: 10px;
}

.testPanel {
    position: fixed;
    top: 0px;
    left: 0px;
    background: #99c199;
}