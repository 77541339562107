.timeline {
    position: relative;
    margin-top: 50px;
    .leftSide {
        width: calc(30% - 20px);
        margin-right: 20px;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        .headerIcon {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            i {
                font-size: 16px;
                margin-right: 5px;
                margin-top: 5px;
            }
            h3 {
                font-size: 18px;
                font-weight: 600;
            }
            .onHoldIcon {
                color: #d83b01;
            }
            .noFurtherActionIcon {
                color: #107c10;
            }
            .withdrawnIcon {
                color: #107c10;
            }
            .cancelledIcon {
                color: #107c10;
            }
            .supersededIcon {
                color: #107c10;
            }
            .closedIcon {
                color: #107c10;
            }
        }
    }
    .rightSide {
        width: calc(70% - 20px);
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        .content {
            margin: 0;
            color: #605e5c;
            font-size: 14px;
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            line-height: 23px;
            h4 {
                font-weight: 600;
                font-size: 14px;
                color: #323130;
                margin: 0 5px 0 0;
            }
            .people {
                color: #0052c2;
                font-size: 14px;
                margin: 0 5px;
            }
            .label {
                font-weight: 600;
                margin: 0;
            }
        }
        .contentWithoutFlex {
            margin: 0;
            color: #605e5c;
            font-size: 14px;
            line-height: 23px;
            .spanHeader {
                font-weight: 600;
                font-size: 14px;
                color: #323130;
                margin: 0 5px 0 0;
            }
        }
        .activeButtonsContainer {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: 25px;
            align-items: center;
            .viewButton {
                margin: 5px;
                margin-left: 0;
            }
        }
    }
    .reason {
        margin-bottom: 15px;
    }
    .midArea {
        width: 40px;
    }
    .label {
        padding: 0;
        margin: 0;
        text-align: end;
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 5px;
    }
    .lineContainer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        > div {
            width: 100%;
            height: 100%;
            display: flex;
        }
        .line {
            display: block;
            height: 100%;
            width: 2px;
            background-color: #c7e0f4;
            margin: 0px auto;
            position: relative;
            z-index: 1;
            &.singleTimeline {
                height: calc(100% - 60px);
                margin-top: 25px;
                &.shortenedLine {
                    height: calc(100% - 590px);
                }
                &.mobileLine {
                    margin-top: 25px;
                }
                &.mobileShortenedLine {
                    height: calc(100% - 335px);
                }
                &.manageSignaturesLine {
                    height: calc(100% - 55px);
                    margin-top: 25px;
                }
            }
            &.doubleTimeline {
                height: calc(100% - 110px);
                margin-top: 80px;
                &.shortenedLine {
                    height: calc(100% - 650px);
                }
                &.manageSignaturesLine {
                    margin-top: 80px;
                }
            }
        }
    }
    .point {
        position: relative;
        width: 23px;
        height: 23px;
        background-color: #faf9f8;
        border-radius: 50%;
        margin: 0px auto;
        position: relative;
        z-index: 1;
        &:before,
        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 50%;
            box-sizing: border-box;
        }
        &:before {
            width: 19px;
            height: 19px;
            margin: 2px;
        }
        &:after {
            width: 15px;
            height: 15px;
            margin: 4px;
        }
        &.closed {
            background: transparent;
            &:before {
                background-color: #faf9f8;
            }
            &:after {
                background-color: #2b88d8;
            }
        }
        &.current {
            background-color: transparent;
            &:before {
                background-color: #0052c2;
            }
            &:after {
                background-color: #0052c2;
                border: 2px solid #faf9f8;
            }
        }
        &.opened {
            background: transparent;
            &:before {
                background-color: #faf9f8;
            }
            &:after {
                background-color: #faf9f8;
                border: 2px solid #2b88d8;
            }
        }
    }
    .peopleContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        * {
            display: flex;
        }
        margin-right: 5px;
    }
    .taskItem {
        min-width: 60%; // prevent 2 on 1 line
    }
}

.departmentActivityTimeline {
    position: relative;
    .title {
        color: #323130;
        font-size: 20px;
        font-weight: 600;
        padding: 8px 0px;
    }
    .content {
        background: #faf9f8;
        padding: 0px 0px;
    }
    .infoContainer {
        position: relative;
        background: transparent;
        z-index: 1;
    }
    .infoItem {
        display: flex;
        padding: 20px 0px;
    }
    .peopleContainer {
        margin-left: -5px;
        margin-right: 5px;
    }
}

.recordStagesTimeline {
    width: 100%;
    .listOfStages {
        position: relative;
        margin: 0 auto;
        width: 100%;
        margin-top: 50px;
        margin-bottom: 160px;
        .additionalTimelineTitle {
            color: #323130;
            font-size: 20px;
            font-weight: 600;
            padding: 8px 0px;
            z-index: 1;
            position: relative;
            span {
                background-color: white;
            }
        }
        ul {
            width: 100%;
            padding: 0;
            > li {
                list-style-type: none;
            }
        }
    }
    .stage {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 24px 0 24px 0;
        &.active {
            background-color: #deecf9;
            border-left: 3px solid #0052c2;
            .label {
                font-size: 18px;
            }
            .midArea {
                .point {
                    margin-left: 6px;
                }
            }
        }
        &.predecessorActiveStep {
            border-left: 3px solid #0052c2;
            background: #eff6fc;
            .midArea {
                .point {
                    margin-left: 6px;
                }
            }
        }
        &.isSupersededFrom {
            background: rgba(95, 210, 85, 0.2) !important;
        }
        &.predecessorIsSupersededFrom {
            background: rgba(95, 210, 85, 0.3) !important;
        }
        .dueDateContainer {
            display: flex;
            flex-direction: row;
            max-width: 110px;
            align-items: flex-start;
            flex-wrap: wrap;
            i {
                margin-right: 7px;
                margin-top: 3px;
                font-size: 12px;
                color: #323130;
            }
            p {
                margin: 0;
                font-size: 12px;
                line-height: 16px;
                color: #323130;
                text-align: end;
            }
        }
        .calendarDate {
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            i {
                margin-right: 10px;
                font-size: 16px;
                color: #0052c2;
                margin-top: 3px;
            }
            span {
                font-size: 16px;
                font-weight: 600;
                color: #0052c2;
                white-space: nowrap;
            }
        }
        .spinnerStyling {
            margin-right: 7px;
            margin-left: 5px;
        }
        .wantOptionsButton {
            margin-top: 15px;
        }
        .leftSectionAdditionalInfo {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
    }
    .additionalStep {
        height: 200px;
        .rightSide {
            .activeButtonsContainer {
                margin-top: 0;
            }
        }
    }
    .onHoldStep {
        background: rgba(250, 65, 0, 0.2) !important;
    }
    .noFurtherActionStep {
        background: rgba(95, 210, 85, 0.2) !important;
    }
    .withdrawnStep {
        background: rgba(95, 210, 85, 0.2) !important;
    }
    .cancelledStep {
        background: rgba(95, 210, 85, 0.2) !important;
    }
    .supersededStep {
        background: rgba(95, 210, 85, 0.2) !important;
    }
    .closedStep {
        background: rgba(95, 210, 85, 0.2) !important;
    }
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.82);
    z-index: 2;
    cursor: progress;
    >div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
    }
}

@media (max-width: 640px) {
    .timeline {
        margin-top: -13px;
        .leftSide {
            display: none;
        }
        .rightSide {
            margin-left: 8px;
            // max-width: 250px;
            min-width: 250px;
            width: 95%;
            .activeButtonsContainer {
                gap: 16px;
            }
        }
        .midArea {
            width: 23px;
            .line {
                margin-left: 10px;
            }
            .point {
                margin-left: 3px;
            }
        }
        .label {
            line-height: 23px;
            text-align: start;
            margin-bottom: 8px;
        }
        .lineContainer {
            left: 10px;
            .line {
                &.singleTimeline {
                    height: calc(100% - 100px);
                    margin-top: 10px;
                }
                &.doubleTimeline {
                    height: calc(100% - 500px);
                    margin-top: 80px;
                }
            }
        }
        .taskItem {
            line-height: 20px;
        }
    }
    .recordStagesTimeline {
        .listOfStages {
            margin-bottom: 20px;
            margin-top: 0;
        }
        .stage {
            justify-content: left;
            flex-direction: row;
            margin-left: -20px;
            margin-right: -20px;
            padding-left: 27px;
            padding-right: 30px;
            &.active {
                .rightSide {
                    margin-left: 8px;
                }
            }
            &.active {
                .midArea {
                    .point {
                        margin: 0px auto;
                    }
                }
            }
            &.predecessorActiveStep {
                .midArea {
                    .point {
                        margin: 0px auto;
                    }
                }
            }
            .calendarDate {
                justify-content: flex-start;
                margin-top: 5px;
            }
            .dueDateContainer {
                max-width: 100%;
                margin-bottom: 10px;
                p {
                    text-align: start;
                }
            }
        }
        .additionalTimelineTitle {
            margin-left: 40px;
            margin-top: 40px;
            span {
                background-color: inherit !important;
            }
        }
    }
    .departmentActivityTimeline {
        margin-top: 1px;
        .title {
            margin-left: 40px;
            padding-top: 40px;
        }
        .infoItem {
            padding: 16px 0px;
        }
        .content {
            padding: 0px 6px;
        }
    }
}

.blockedTimeline {
    padding-bottom: 160px;
}

.roleNameInfo {
    color: #0052c2;
    font-size: 14px;
    margin: 0 5px;
}

.textInfoContainer {
    margin-right: 5px;
}

.mobileBlockedSectionLabel {
    font-size: 18px !important;
}

.svgGraph {
    position: absolute;
    z-index: 2;
    background: white;
    width: 20px;
    height: 20px;
    overflow: hidden;
    cursor: pointer;
    border-top: 1px solid lightgrey;
    &:hover {
        width: 100%;
        height: inherit;
    }
}
