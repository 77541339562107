@import "../../../styles/variables";
.peoplePickerContainer {
    width: 100%;
    position: relative;
    .peoplePickerLabel {
        width: 100%;
        padding-bottom: 0;
    }
    .peoplePickerFakeLabelForAccessibilityInsights {
        padding: 0px;
        width: 100%;
    }
    .peoplePicker {
        margin-top: 10px;
    }
    .requiredFlag {
        color: $statusRequaredIcon;
        position: absolute;
        top: 0;
        right: 5px;
    }
}