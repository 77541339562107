@import "./ManageRecordCommon.module.scss";
.manageRecordDetailsContainer {
    .footer {
        width: 100%;
        .btnsContainer {
            margin: 10px 0;
            display: flex;
            gap: 10px;
            margin: 25px 0;
            .updateBtn {
                .text,
                .spinner {
                    margin: 0px 4px;
                }
            }
        }
    }
    .formContainer {
        .commentsDescrContainer {
            display: flex;
            flex-direction: column;
            .commentsDescrHeader {
                font-weight: 600;
                font-size: 16px;
                margin-bottom: 15px;
            }
            .commentsDescrText {
                max-width: 380px;
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 20px;
            }
        }
        .iconStyling{
            margin-left: 5px;
        }
    }
}