.recordsStatisticsContainer {
    min-height: 100px;
    width: 100%;
    background-color: #f3f2f1;
    padding: 30px;
    @media (max-width: 640px) {
        padding: 20px;
    }
    .recordsCardsInfoContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        @media (max-width: 1365px) {
            justify-content: center;
            padding-top: 5px;
        }
        .pivotContainer {
            align-items: center;
            display: flex;
            flex-direction: column;

            :global(.ms-Button-flexContainer) {
                justify-content: center;
            }

            :global(.ms-Button) {
                margin-right: 0!important;
                height: 70px;
            }

            .pivotItemContainer {
                text-align: center;
                .pivotIcon {
                    display: block;
                    font-size: 20px;
                }
            }
        }
        // .pivotItem {
        //     button {
        //         height: 75px;
        //         div[class="ms-Pivot-linkContent"] {
        //             display: flex;
        //             flex-direction: column;
        //             span[class="ms-Pivot-icon"] {
        //                 height: 35px;
        //                 i {
        //                     font-size: 19px;
        //                 }
        //             }
        //         }
        //     }
        // }
    }
}
