//type
$typePrimary: #323130;
$typeSecondary: #605e5c;
$typeDisabled: #a19f9d;

//divider-&-border
$bodyDivider: #edebe9;
$inputBorder: #8a8886;
$inputHoverBorder: #323130;

//theme
$themePrimary: #0052c2;
$themeTertiary: #2b88d8;
$themeDarkAlt: #106ebe;
$themeDark: #005a9e;
$themeDarker: #004578;
$themeLight: #c7e0f4;
$themeLighter: #deecf9;
$themeLighterAlt: #eff6fc;

//error-&-status
$errorBackgroundFill: #fde7e9;
$statusErrorTypeOrIcon: #a80000;
$successBackgroundFill: #dff6dd;
$statusSuccessTypeOrIcon: #107c10;
$severeWarningBackgroundFill: #fed9cc;
$statusSevereWarningTypeOrIcon: #d83b01;
$warningBackgroundFill: #fff4ce;
$statusWarningTypeOrIcon: #797775;
$statusRequaredIcon: #fc0303;

//overlays
$overlayLight: lighten(#fff, 40%);
$overlayDark: lighten(#000, 40%);

//greys
$white: #fff;
$grey10: #faf9f8;
$grey20: #f3f2f1;
$grey30: #edebe9;
$grey40: #e1dfdd;
$grey50: #d2d0ce;
$grey60: #c8c6c4;
$grey90: #a19f9d;
$grey130: #605e5c;
$grey150: #3b3a39;
$grey160: #323130;
$grey190: #201f1e;
