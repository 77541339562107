.preHeaderBanner{
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    background-color: yellow;
}
.header {
    display: flex;
    justify-content: space-between;
    background: #0e0848;
    height: 48px;
    padding: 8px 16px;
    .logoContainer {
        display: flex;
        width: 100%;
        .logo {
            width: 90px;
            height: 100%;
            background: url("./abclogo.svg");
            background-size: contain;
            background-repeat: no-repeat;
            cursor: pointer;
        }
    }
    .searchContainer {
        display: flex;
        width: 100%;
        .search {
            width: 100%;
            background: #004c97;
            color: white;
            border-color: #004c97;
            :global(.ms-SearchBox-iconContainer) {
                color: white;
            }
            :global(.ms-Button-icon) {
                color: white;
                
            }
            input {
                color: white;
                &::placeholder {
                    color: white;
                }
                &:-ms-input-placeholder {
                    color: white;
                }
                &::-ms-input-placeholder {
                    color: white;
                }
            }
            button {
                &:hover {
                    background: white;
                    i {
                        color: #004c97;
                    }
                }
            }
        }
        @media (max-width: 640px) {
            display: none;
        }
    }
    .mobileSearch {
        display: none;
        @media (max-width: 640px) {
            display: block;
        }
    }
    .commandBarContainer {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        .toolTip{
            color: white;
        }
        .btn {
            color: white;
            background: transparent;
            margin: 0px 4px;
            &:hover {
                color: #2b88d8;
                background: transparent;
            }
        }
    }
}

.errorDialog{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: white;
    z-index: 9999;
    color: red;
    padding: 10px;
    border: 1px solid red;
    opacity: 0.8;
}

.collapsed:not(:hover) {
    height: 4px;
    width: 4px;
    background-color: #ffe4e4;
}