.snapshotTableContainer{
    width: 100%;
    .bodyColumn {
        padding: 10px 0;
        vertical-align: middle;
        .primaryText {
            font-size: 16px;
            font-weight: 400;
        }
        .secondaryText {
            font-size: 14px;
            color: #605e5c;
        }
        .tertiaryText {
            font-size: 14px;
            color: #605e5c;
            font-weight: 500;
        }
        .linkText {
            color: #0052c2;
            text-decoration: none;
            font-size: 14px
        }
    }
}