@import "../../styles/variables";
.divider {
    width: 95%;
    height: 1px;
    background: #edebe9;
    margin: 7px 0;
    @media (max-width: 640px) {
        margin: 16px 0;
    }
}

.overviewRecommendContainer {
    width: 100%;
    left: 0;
    background-color: #f3f2f1;
    margin-top: 25px;
    @media (max-width: 640px) {
        min-height: 0px;
        padding-bottom: 2px;
    }
    .overviewRecommendDataContainer {
        display: flex;
        flex-direction: column;
        padding: 30px 0 30px 0;
        width: 80%;
        margin: auto;
        @media (max-width: 640px) {
            padding-top: 32px;
        }
        .submitButton {
            max-width: 300px;
        }
        .subjectContainer {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-top: 30px;
            @media (max-width: 640px) {
                padding-top: 16px;
            }
            .topicAnalysisSection,
            .replyRecommendationSection,
            .responseDetailsContainer {
                display: flex;
                flex-direction: column;
                background-color: #ffffff;
                border-radius: 2px;
                box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.108);
                padding: 5px 40px 15px 40px;
                margin: 0 0 15px 0;
                @media (max-width: 640px) {
                    padding: 16px 16px 32px;
                }
            }
            .topicAnalysisSection {
                min-height: 185px;
                @media (max-width: 640px) {
                    min-height: 0px;
                }
            }
            .blockedTopicAnalysisSection {
                background-color: #faf9f8;
                opacity: 0.7;
            }
            .recommendationsSection {
                min-height: 100px;
                background-color: #deecf9;
                padding: 5px 40px 30px 40px;
                box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
                border-radius: 2px;
                border-left: 3px solid #0052c2;
                @media (max-width: 640px) {
                    border-left: 4px solid #0052c2;
                    padding: 16px 16px 32px;
                }
                .button {
                    display: flex;
                    max-width: 350px;
                    margin-left: auto;
                    margin-top: 16px;
                }
                .decisionMadeContainer {
                    display: flex;
                    margin-top: 16px;
                    .icon {
                        color: #107C10;
                        display: inline-block;
                        vertical-align: text-top;
                        margin-right: 8px;
                        font-size: 16px;
                        .successIcon {
                            padding-top: 2px;
                        }
                    }
                    .approveMessage {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 20px;
                        * {
                            display: inline-block;
                            margin: 0px 2px;
                        }
                    }
                }
                .replyContainer {
                    padding-top: 15px;
                    .replyCommentsLabel {
                        font-weight: 600;
                        line-height: 20px;
                    }
                }
                .footer {
                    display: flex;
                    max-width: 250px;
                    margin-left: auto;
                    margin-top: 16px;
                    .icon {
                        margin-right: 8px;
                        font-size: 12px;
                        display: inline-block;
                        cursor: help;
                        vertical-align: text-top;
                        .infoIcon {
                            padding-top: 3px;
                        }
                    }
                    .descr {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        color: #605e5c;
                    }
                }
            }
            .blockedRecommendationsSection {
                background-color: #C7E0F4;
                border-left: 3px solid #deecf9;
                opacity: 0.7;
            }
            .sybjectHeader {
                font-weight: 600;
                font-size: 18px;
                @media (max-width: 640px) {
                    margin: 0;
                }
            }
            .subjectText {
                @media (max-width: 640px) {
                    margin: 4px 0 0;
                }
            }
        }
    }
    .mobileOverviewRecommendDataContainer {
        width: auto;
        margin: 0px 20px;
    }
}

.recommendationPreviewItem {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
    .info {
        display: flex;
        .number {
            margin-right: 16px;
            font-size: 14px;
            font-weight: 600;
        }
        .type {
            text-transform: uppercase;
            padding-right: 4px;
            font-size: 14px;
            font-weight: 600;
        }
        .mobileDescr {
            display: flex;
            flex-direction: column;
        }
        .recommendationPreviewItemDescr {
            word-break: break-word;
        }
    }
    .OptionsContainer {
        width: 37%;
    }
    .mobileInfo {}
    .checkboxes {
        display: inline-flex;
        flex-direction: row;
		flex-wrap:weight;
        justify-content: flex-start;
		
		width:50%;
        min-width:175px;
        padding-left: 20px;
    }
    .makeDesicionCheckboxes {
         :global {
            .is-checked {
                .ms-Checkbox-checkbox {
                    background: $themePrimary;
                    border: 1px solid $themePrimary;
                }
                .ms-Checkbox-text {
                    color: black;
                }
            }
        }
    }
    .mobileCheckboxex {
        flex-direction: column;
        height: 90px;
        min-width: 200px;
    }
}

.responseDetailsContainer {
    .responseDetailsHeader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 20px;
        align-items: center;
        padding: 15px 0 7px;
    }
    .columnType {
        font-weight: 600;
        font-size: 14px;
        color: $typePrimary;
        margin-right: 4px;
    }
    .responseDetailsContent {
        .responseDetailsCRow {
            display: flex;
            flex-wrap: wrap;
            padding: 8px 0;
        }
        .columnType {
            width: 200px;
        }
    }
    .contactsContainer {
        display: flex;
        flex-wrap: wrap;
        .contactColumn {
            width: 50%;
            .columnType {
                padding: 8px 0;
            }
        }
        .contactsList {
            &>* {
                padding: 8px 0;
            }
            &> :not(:last-child) {
                border-bottom: 1px solid $bodyDivider;
            }
        }
    }
}

@media (max-width: 850px) {
    .recommendationPreviewItem {
        flex-direction: column;
        >* {
            padding: 8px 0px;
        }
    }
    .responseDetailsContainer {
        .contactsContainer {
            .contactColumn {
                width: 100%;
            }
        }
    }
}


