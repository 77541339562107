@import "../../../styles/variables";

.peopleContainer {
    display: flex;
    flex-direction: row;
    .hoverPersonCard {
        color: $themePrimary;
        font-size: 14px;
        font-weight: 600;
    }
    .additionalUsersText {
        font-size: 14px;
        color: $themePrimary;
        font-size: 14px;
        font-weight: 600;
        margin: 0 5px;
    }
    .onBehalfOfText {
        margin: 0 5px;
    }
}
.additionalText{
    margin-left: 5px;
}
.additionalUsersContainer {
    padding: 8px;
    min-width: 150px;
    display: flex;
    flex-direction: column;
    .additionalUser {
        margin: 5px 0;
    }
}