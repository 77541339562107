.noResults {
  font-weight: 100;
  margin-top: 0px;

  font-size: 16px;
  @media (max-width: 1023px) {
    font-size: 20px;
  }
}

.isError {
  color: red;
}
