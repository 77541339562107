.dashboard {
    width: 100%;
}
.bgGrey {
    background-color: #f3f2f1;
}
.bgWhite {
    background-color: white;
}
.pageInfoBarContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 0 10%;
    width: 100%;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-top: 15px;
    @media (max-width: 640px) {
        padding: 0 20px;
    }

    .dashboardMobileButtons {
        border-bottom: 1px solid #edebe9;
        padding: 0 0 24px;
        width: 100%;
        .buttonContainer {
            display: inline-block;
            width: 100%;
            .buttonStyling {
                margin-top: 2px;
            }
        }
        .buttonContainerStandard {
            vertical-align: top;
        }
    }
}
