@import "../../styles/variables";

.userInfoContainer {
    // max-width: 450px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    .titleContainer {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        .title{
            font-weight: 600;
            font-size: 32px;
            line-height: 40px;
        }
    }
    .displayNameUserContainer {
        display: flex;
        flex-direction: row;
        margin-top: -10px;
        .welcomeMessage {
            font-weight: 600;
            font-size: 20px;
            line-height: 27px;
            margin: 10px 0;
            @media screen {
                line-height: 28px;
                margin: 8px 0;
            }
        }
        span {
            color: $themePrimary;
        }
    }
    .currentPage {
        margin-bottom: 15px;
        font-weight: 600;
    }
    .userLevels {
        margin: 0;
        @media screen {
            font-size: 12px;
            line-height: 16px;
        }
    }
    .userRoles {
        font-size: 13px;
        color: #757575;
        margin: 5px 0;
        span {
            font-weight: 500;
        }
    }
    .proxies {
        margin: 5px 0;
        font-size: 13px;
        color: #757575;
        @media screen {
            font-size: 12px;
            line-height: 16px;
        }
        span {
            font-weight: 500;
        }
    }
}
