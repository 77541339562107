/* Center container for login states */
.centerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
    text-align: center;
}

/* Sign-In box styling */
.signIn {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    max-width: 400px;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Error box styling */
.signInError {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    max-width: 400px;
    background: #ffeded;
    border: 1px solid #ff6f6f;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #b22222;
}

/* Retry Button */
.retryButton {
    margin-top: 10px;

}
