.tableHeader {
    width: 100%;
    .tableHeaderTopContainer {
        width: 100%;
        padding: 10px 0 16px;
        border-bottom: 1px solid #edebe9;
        .buttonLeft {
            margin-right: 16px;
            display: inline-block;
        }
        .searchField {
            display: inline-block;
            max-width: 700px;
            vertical-align: top;
            margin-right: 16px;
        }
        .resultsText {
            color: #605e5c;
            font-size: 14px;
            display: inline-block;
        }
    }
    .tableHeaderBottomContainer {
        width: 100%;
        padding: 10px 0 16px;
        .itemSelectionText {
            color: #605e5c;
            font-size: 14px;
            display: inline-block;
            padding: 4px;
        }
        .editColumnsText {
            .pencilIcon {
                margin-right: 10px;
            }
            color: #201f1e;
            float: right;
            padding: 4px;
            cursor: pointer;
        }
    }
}

.table {
    width: 100%;

    div[data-automationid="DetailsList"] {
        overflow-y: hidden;
    }

    .allowWordWrapContainer {
        width: auto;
        text-align: right;
    }

    .allowWordWrapCheckboxContainer {
        display: inline-block;
        vertical-align: top;
        padding: 14px 7px 0 7px;
    }

    .list {
        background-color: "[theme: white, default: #fff]"; //#fff;
        div[class^="ms-DetailsRow-fields fields-"] {
            align-items: center;
        }
        div {
            background-color: "[theme: white, default: #fff]"; //#fff;
        }

        span[role="button"] {
            cursor: pointer;
        }

        i {
            font-size: 1.33em;
        }

        div[data-automationid="DetailsHeader"] {
            padding-top: 2px;
        }

        &__wordWrap {
            div {
                white-space: normal;
            }
        }
    }
}
.tableBorder {
    border-top: 2px solid #c8c8c8;
    border-bottom: 2px solid #c8c8c8;
}

@media print {
    .table {
        div[data-automationid="ListCell"] {
            page-break-inside: avoid;
        }
        div[data-automationid="DetailsRowFields"] {
            page-break-inside: avoid;
            overflow-y: visible;
            overflow-x: visible;
        }
        div[role="grid"][aria-colcount="6"] {
            div[class="ms-List-cell"] {
                div[data-automationid="DetailsRow"] {
                    font-size: 10px;
                    div[data-automationid="DetailsRowCell"] {
                        max-width: 200px;
                    }
                }
                div[role="rowheader"] {
                    font-size: 10px;
                }
            }
            div[data-automationid="DetailsHeader"] {
                div[data-automationid="ColumnsHeaderColumn"] {
                    max-width: 200px;
                }
            }
        }

        div[role="grid"][aria-colcount="14"] {
            div[class="ms-List-cell"] {
                div[data-automationid="DetailsRow"] {
                    div[role="gridcell"] {
                        width: 0;
                    }
                    div[data-automationid="DetailsRowCell"] {
                        max-width: 70px;
                    }
                    div[data-automationid="DetailsRowFields"] {
                        div[role="rowheader"] {
                            font-size: 8px;
                        }
                    }
                }
            }
            div[data-automationid="DetailsHeader"] {
                div[data-automationid="DetailsRowCheck"] {
                    width: 0;
                }
                div[data-automationid="ColumnsHeaderColumn"] {
                    max-width: 70px;
                }
            }
        }

        div[role="grid"][aria-colcount="5"] {
            div[class="ms-List-cell"] {
                div[data-automationid="DetailsRow"] {
                    div[data-automationid="DetailsRowCell"] {
                        max-width: 250px;
                        min-width: 210px;
                    }
                    div[data-automationid="DetailsRowFields"] {
                        div[role="rowheader"] {
                            font-size: 7px;
                        }
                    }
                }
            }
            div[data-automationid="DetailsHeader"] {
                div[data-automationid="ColumnsHeaderColumn"] {
                    max-width: 250px;
                    min-width: 210px;
                }
            }
        }
    }
}
