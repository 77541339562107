.recordsCardInfo {
    background: #ffffff;
    box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.108);
    border-radius: 2px;
    width: 380px;
    height: 260px;
    padding: 15px;
    margin: 10px 0;
    @media (max-width: 1365px) {
        margin: 10px;
    }
    .recordsProgressContainer {
        display: flex;
        flex-direction: column;
        .cardTextInfo {
            display: flex;
            flex-direction: column;
            // margin-bottom: 20px;
            .cardHeader {
                display: flex;
                flex-direction: row;
                h3 {
                    font-weight: 600;
                    font-size: 18px;
                    margin: -2px 0 0 5px;
                    @media (max-width: 640px) {
                        margin: -2px 0 0 8px;
                    }
                }
                i {
                    color: #0052c2;
                    font-size: 18px;
                }
            }
            p {
                color: #464a4f;
                font-size: 12px;
            }
        }
        .recordsProgressInfoContainer {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            position: relative;
            
            .chartProgressContainer {
                display: flex;
                flex-direction: column;
                position: relative;
                width: 300px; //370px/190px
                height: 150px;
                p {
                    color: #605e5c;
                    font-size: 10px;
                    margin: -5px 0 0;
                    font-weight: bold;
                    width: 130px;
                    white-space: nowrap;
                    text-align: center;
                    @media (max-width: 640px) {
                        margin: 5px 0 0;
                    }
                }
                canvas {
                    z-index: 2;
                }
                .countAllRecordsInChart {
                    width: 115px;
                    position: absolute;
                    top: 35%;
                    left: 0;
                    text-align: center;
                    font-size: 30px;
                    font-weight: bold;
                    justify-content: center;
                    align-items: center;
                    z-index: 1;
                }
                .mobileCountAllRecordsInChart {
                    top: 12%;
                    width: 280px;
                    left: 0;
                    font-size: 35px;
                    @media (max-width: 640px) {
                        left: -7%;
                    }
                }
            }
            .mobileChartProgressContainer {
                width: 100%;
                height: 250px;
                p {
                    text-align: center;
                    margin-left: 0px;
                    position: absolute;
                    left: 27%;
                    top: 43%;
                }
            }
            .chartInfoContainer {
                position: absolute;
                bottom: 0;
                right: 58px;
                top: 112px;
                width: 170px;

                .toggleRecordsContainer {
                    .toggle {
                        label {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}
.mobileRecordsCardInfo {
    width: auto;
    max-width: 280px;
    height: 350px;
    padding: 16px;
}
