.recordsCardInfo {
    background: #ffffff;
    box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.108);
    border-radius: 2px;
    width: 380px;
    height: 260px;
    padding: 15px;
    margin: 10px 0;
    @media (max-width: 1365px) {
        margin: 10px;
    }
    .recordsTimelinessContainer {
        display: flex;
        flex-direction: column;
        .cardTextInfo {
            display: flex;
            flex-direction: column;
            // margin-bottom: 20px;
            .cardHeader {
                display: flex;
                flex-direction: row;
                h3 {
                    font-weight: 600;
                    font-size: 18px;
                    margin: -2px 0 0 5px;
                    @media (max-width: 640px) {
                        margin: -2px 0 0 8px;
                    }
                }
                i {
                    color: #0052c2;
                    font-size: 18px;
                }
            }
            p {
                color: #464a4f;
                font-size: 12px;
            }
        }
        .recordsTimelinessInfoContainer {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            position: relative;
            .chartTimelinessContainer {
                display: flex;
                flex-direction: column;
                position: relative;
                width: 260px;
                height: 150px;
                p {
                    color: #605e5c;
                    font-size: 10px;
                    margin: -5px 0 0;
                    font-weight: bold;
                    width: 130px;
                    text-align: center;
                    @media (max-width: 640px) {
                        margin: 5px 0 0;
                    }
                }
                canvas {
                    z-index: 2;
                }
                .maxTimelinessPercent {
                    width: 130px;
                    position: absolute;
                    top: 35%;
                    left: 0;
                    text-align: center;
                    font-size: 30px;
                    font-weight: bold;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    z-index: 1;
                    span {
                        font-size: 20px;
                        margin-bottom: 12px;
                    }
                }
                .mobileMaxTimelinessPercent {
                    top: 15%;
                    left: 24%;
                    font-size: 25px;
                }
            }
            .mobileChartTimelinessContainer {
                width: 100%;
                height: 180px;
                p {
                    position: absolute;
                    top: 50%;
                    left: 24%;
                }
            }
            .chartInfoContainer {
                position: absolute;
                bottom: 0;
                right: 40px;
                top: 95px;
                width: 160px;
                z-index: 2;

                .toggleRecordsContainer {
                    .toggle {
                        label {
                            font-size: 12px;
                        }
                    }
                }
            }
            .mobileChartInfoContainer {
                position: relative;
                margin-top: 20px;
                top: 0;
                right: 0;
            }
        }
        .mobileRecordsTimelinessInfoContainer {
            flex-direction: column;
        }
    }
    .overdueBgr {
        background: #d83b01;
    }
    .onTimeBgr {
        background: #0052c2;
    }
}
.mobileRecordsCardInfo {
    width: auto;
    max-width: 280px;
    height: 350px;
}
