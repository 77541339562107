.headerContainer {
    width: 100%;
    max-width: 620px;
    margin-bottom: 60px;
    h1 {
        font-size: 28px;
        line-height: 36px;
        font-weight: 600;
        margin-block-start: 1em;
        margin-block-end: 1em;
    }
    h3 {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
    }
    ul {
        padding-inline-start: 15px;
        li {
            padding-inline-start: 10px;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            padding-bottom: 8px;
        }
    }
}
