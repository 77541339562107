@import "../../styles/variables";
@import "./ManageRecordCommon.module";
.manageRecordDocumentContainer {
    font-weight: normal;
    .buttonContainer {
        padding-top: 42px;
        padding-bottom: 52px;
        border-bottom: solid $grey20 2px;
        @media (max-width: 640px) {
            padding: 32px 0;
        }
        .errorContainer {
            margin: 10px 0px;
        }
    }
    .primaryButtonStyling {
        margin-right: 16px;
    }
    .generateDocumentWithAIButtonStyling {
        padding-left: 8px;
        margin-right: 16px;
    }
    .signedInstructionDesc {
        font-size: 14px;
        font-weight: 600;
        margin-top: 32px;
    }
    .manageSignatureInstructBtn {
        margin: 20px 0;
    }
    .iconStyling {
        font-size: 14px;
        vertical-align: middle;
        margin-left: 5px;
        cursor: help;
    }
    .buttonStyling {
        margin-right: 16px;
        vertical-align: bottom;
        min-width: 270px;
    }
    .spinnerStyling {
        display: inline-block;
        vertical-align: middle;
    }
    .fileLink {
        display: inline-block;
        color: #106ebe;
        font-weight: 500;
        display: none;
    }
    .sectionHeader {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        margin: 0;
        // text-decoration: underline;
    }
    .sectionHeader {
        margin-top: 16px;
    }
    .sectionContent {
        padding-top: 16px;
    }
    .signedDocumentCheckbox {
        // width: 50%;
        max-width: 380px;
    }
    .dwpNAttachContainer {
        border-bottom: solid #f3f2f1 2px;
        padding-top: 33px;
        padding-bottom: 48px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }
    .dwpSection {
        max-width: 483px;
        flex-basis: 50%;
    }
    .attachmentSection {
        flex-basis: 50%;
    }
    .supportingDocContainer {
        border-bottom: solid #f3f2f1 2px;
        padding-top: 33px;
        padding-bottom: 48px;
    }
    .signatureDocContainer {
        border-bottom: solid $grey20 2px;
        padding-top: 33px;
        padding-bottom: 48px;
        .documentsSignatureInstructionsContainer {
            min-height: 75px;
            background: $grey20;
            margin-bottom: 20px;
            padding: 3px 15px;
            .noHasDocuments {
                display: flex;
                flex-direction: row;
                color: $statusWarningTypeOrIcon;
                font-size: 14px;
                i {
                    margin-right: 10px;
                    margin-top: 3px;
                    color: $grey190;
                }
            }
            .documentsSignatureInstructionsItems {
                background-color: $white;
                margin: 20px 5px;
                display: flex;
                flex-direction: column;
                .documSignatureItem {
                    min-height: 50px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: 1px solid $grey20;
                    padding: 0 15px;
                    .tabHeader {
                        font-weight: 600;
                        font-size: 16px;
                        width: 50%;
                    }
                    .documentNameTabContainer {
                        display: flex;
                        flex-direction: row;
                        .iconColumnIcon {
                            color: $themePrimary;
                            margin: 2px 20px 0 0;
                        }
                    }
                    .tabValue {
                        color: $grey190;
                        width: 50%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        min-width: 300px;
                    }
                    .tabInstuctionsValue {
                        color: $grey190;
                    }
                }
            }
        }
    }
    .snapShotsContainer {
        border-bottom: solid #f3f2f1 2px;
        padding-top: 33px;
        padding-bottom: 48px;
        .sectionHeader {
            margin-bottom: 24px;
        }
    }
    .secondaryContainer {
        background-color: #f3f2f1;
        padding: 20px;
        // .snapshotsTable {
    }
    @media (max-width: 640px) {
        .dwpNAttachContainer {
            border-bottom: solid #f3f2f1 2px;
            padding-top: 16px;
            padding-bottom: 0;
            display: block;
        }
        .dwpSection {
            border-bottom: solid #f3f2f1 2px;
            padding-bottom: 32px;
        }
        .attachmentSection,
        .signatureDocContainer,
        .supportingDocContainer,
        .snapShotsContainer {
            padding-top: 16px;
            padding-bottom: 32px
        }
        .snapShotsContainer {
            border-bottom: none;
        }
        .secondaryContainer {
            padding: 0;
        }
        .buttonStyling {
            margin-top: 16px;
        }
    }
}

.spinnerContainer {
    padding-top: 20px;
}