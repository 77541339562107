.positionNextToBreadCrumbs{
    position: absolute;
    right: 20px;
    top: 60px;
}

.buttonWrapper {
    white-space: nowrap;
}

.categoryHeader {
    border-bottom: 2px solid #2b88d8;
    padding-top: 3px;
    > button 
        > div 
            > span{
                color: #005a9e;
                font-weight: 600;
    }
}

.actionItem{
    > button {
                padding-left: 10px;
    }
    > div
     > a {
        padding-left: 10px;
    }
}