@import "../../../styles/variables";
.breadcumbs {
    background-color: $white;
    width: 100%;
    padding: 20px 10% 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: 640px) {
        padding: 20px;
    }
    .items {
        > * {
            display: inline-block;
            margin-right: 5px;
            font-weight: 600;
        }
        i {
            font-size: 10px;
        }
        a {
            text-decoration: none;
        }
    }
    .currentPageText {
        display: inline-block;
        font-weight: 600;
        @media (max-width: 640px) {
            font-size: 12px;
        }
    }
    .linkText {
        cursor: pointer;
        color: $grey130;
        font-weight: 400;
    }
    .dashboardText {
        color: $themePrimary;
    }
    .buttons{
        display: flex;
        width: 340px;
        justify-content: space-between;
    }
}

.breadcumbsMobile{
    flex-direction: column;
    padding-bottom: 30px;
    flex-wrap: wrap;
    .buttons{
        flex-wrap: wrap;
        height: 70px;
        flex-direction: column;
    }
    .items{
        padding-bottom: 10px;
    }
}