@import "../../../styles/variables";
.choiceFieldContainer {
    display: flex;
    flex-direction: column;
    .choiceFieldHeaderContainer {
        display: flex;
        flex-direction: row;
    }
    .choiceFieldPeoplePickerContainer {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        .searchPeople {
            min-width: 80%;
        }
        .addUserInChoiceControl {
            margin-left: 20px;
        }
    }
}
.descriptionForm {
    max-width: 80%;
    @media (max-width: 640px) {
        max-width: 100%;
    }
    a,
    a:visited {
        color: #0052c2;
        font-size: 14px;
        text-decoration: none;
    }
    ;
    h2 {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
    }
    h3 {
        font-weight: 600;
        font-size: 15px;
        padding-top: 5px;
        margin: 20px 0 8px;
    }
    mark {
        color: red;
        background-color: transparent;
        display: block;
        padding-top: 10px;
    }
}

.dropDownContainer {
    max-width: 80%;
    margin-bottom: 20px;
    @media (max-width: 640px) {
        max-width: 100%;
    }
}

.dueDateContainer {
    max-width: 80%;
    margin-bottom: 20px;
    @media (max-width: 640px) {
        max-width: 100%;
    }
}

.people {
    color: #0052c2;
    font-size: 14px;
    margin: 0 5px;
    font-weight: 600;
}

.disabledPersonCard {
    color: #dbdbdb;
}

.whoComments {
    margin-top: 10px;
    cursor: help;
}

.iconStyling {
    font-size: 14px;
    vertical-align: middle;
    margin-left: 5px;
    cursor: help;
}

.choiceRoleContainer {
    div[class="ms-ChoiceField-wrapper"] {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        @media (max-width: 640px) {
            flex-direction: column;
        }
    }
    div[class^="ms-ChoiceField-wrapper is-inFocus choiceFieldWrapper-"] {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }
    span[class="ms-ChoiceFieldLabel"] {
        font-weight: 600;
        @media (max-width: 640px) {
            margin-bottom: 5px;
        }
    }
}

.buttonToEnterData {
    font-weight: 600;
    margin-left: 5px;
    margin-top: -5px;
    color: $themePrimary;
    height: 30px;
}

.rolesContainer {
    display: flex;
    flex-direction: column;
    max-height: 150px;
    overflow: auto;
    margin-bottom: 20px;
    .roleControls {
        display: flex;
        flex-direction: row;
        margin-bottom: 5px;
    }
    .roleControls:last-child {
        margin-bottom: 0;
    }
}

.recommendationsSection {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    .sybjectHeader {
        font-weight: 600;
        font-size: 18px;
    }
    .recommendationPreviewItem {
        display: flex;
        flex-direction: column;
        padding: 10px 0px;
        margin-bottom: 20px;
        .info {
            display: flex;
            margin-bottom: 15px;
            .number {
                margin-right: 16px;
                font-size: 14px;
                font-weight: 600;
            }
            .type {
                text-transform: uppercase;
                padding-right: 4px;
                font-size: 14px;
                font-weight: 600;
            }
            .descr {}
        }
        .checkboxes {
            display: flex;
            flex-direction: row;
            min-width: 250px;
            @media (max-width: 850px) {
                flex-direction: column;
                justify-content: space-between;
                height: 90px;
            }
            .OptionsContainer{
                width: 33%;
            }
        }
    }
    .recommendationPreviewItem:last-child {
        margin-bottom: 0;
    }
}

.colon {
    font-size: 14px;  
}

.hoverPeopleCardContainer {
    position: relative;
    z-index: 1;
}

.errorMessage {
    margin: 10px 0px;
}