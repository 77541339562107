@import "../../../styles/variables";

.panelContainer {
    display: flex;
    flex-direction: column;
    .fieldsContainer {
        width: 400px;
        display: flex;
        flex-direction: column;
        margin: 10px 0;
        @media (max-width: 640px) {
            width: auto;
        }
        .addCommentButton {
            width: 150px;
            margin-top: 20px;
        }
        .whoComments {
            text-decoration: underline;
            margin-top: 5px;            
        }
        .whoComments:hover {
            cursor:help;
        }
    }
    .exportCsvContainer{
        padding: 5px 0px;
        .text,
        .spinner {
            margin: 0px 4px;
        }
    }
}
.recordActivityContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 400px;
    min-height: 150px;
    @media (max-width: 640px) {
        width: auto;
    }
    .activityItem {
        display: flex;
        flex-direction: column;
        margin-bottom: 25px;
        .statementContainer {
            display: flex;
            flex-direction: row;
            font-size: 14px;
            i {
                margin-right: 10px;
                margin-top: 3px;
                color: #605e5c;
            }
            .statementContant {
                color: #605e5c;
                .people {
                    color: $themePrimary;
                    font-size: 14px;
                    font-weight: 600;
                    white-space: nowrap;
                }
            }
        }
        .activityInfoContainer {
            margin-left: 25px;
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            .activityLabel {
                span {
                    font-weight: 500;
                    padding-right: 5px;
                }
            }
            .activityDate {
                color: #605e5c;
                font-size: 13px;
                margin-top: 5px;
            }
        }
    }
}

@media (max-width: 640px) {
    .viewButton{
        max-width: 220px;
    }
}