.recordsCardInfo {
    background: #ffffff;
    box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.108);
    border-radius: 2px;
    width: 380px;
    height: 260px;
    padding: 15px;
    margin: 10px 0;
    @media (max-width: 1365px) {
        margin: 10px;
    }
    .recordsPipelineContainer {
        display: flex;
        flex-direction: column;
        .cardTextInfo {
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            @media (max-width: 640px) {
                margin-bottom: 24px;
            }
            .cardHeader {
                display: flex;
                flex-direction: row;
                h3 {
                    font-weight: 600;
                    font-size: 18px;
                    margin: -2px 0 0 5px;
                    @media (max-width: 640px) {
                        margin: -2px 0 0 8px;
                    }
                }
                i {
                    color: #0052c2;
                    font-size: 18px;
                }
            }
            p {
                color: #464a4f;
                font-size: 12px;
            }
        }
        .cardPipelineData {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            .cardPipelineColumn {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex-wrap: wrap;
                max-height: 150px;
                width: 100%;
                .cardItem {
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 15px;
                    cursor: pointer;
                    &:hover {
                        .cardItemText {
                            text-decoration: underline;
                        }
                    }
                    .cardNumberBox {
                        width: 34px;
                        height: 34px;
                        border-radius: 2px;
                        text-align: center;
                        color: #ffffff;
                        font-size: 16px;
                        line-height: 30px;
                    }
                    .cardItemText {
                        line-height: 30px;
                        margin-left: 10px;
                        color: #0052c2;
                        font-size: 14px;
                    }
                    &:last-child{
                        @media (max-width: 640px) {
                            margin-bottom: 32px;
                        }
                        margin-bottom: 0;
                    }
                    
                }
            }
            .mobileCardPipelineColumn {
                max-height: 400px;
            }
        }
    }
    .overdueBgr {
        background: #d83b01;
    }
    .nowBgr {
        background: #107c10;
    }
    .thisWeekBgr {
        background: #2b88d8;
    }
    .nextWeekBgr {
        background: #106ebe;
    }
    .nextMonthBgr {
        background: #005a9e;
    }
    .allRecordBgr {
        background: #004578;
    }
}
.mobileRecordsCardInfo {
    width: auto;
    max-width: 380px;
    height: 410px;
    padding: 16px 16px 32px;

    @media (max-width: 640px) {
        min-width: 280px;
    }
    @media (max-width: 640px) {
        padding-bottom: 32px;
    }
}
