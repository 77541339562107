@import "../../../styles/variables";
.eventManagePanelContainer {
    width: 100%;
    left: 0;
    background-color: #f3f2f1;
    min-height: 450px;
    margin-top: 25px;
    .eventManagePanelDataContainer {
        display: flex;
        flex-direction: column;
        padding: 30px 0 30px 0;
        width: 80%;
        margin: auto;
        .subjectContainer {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-top: 30px;
            .eventInfoContainer {
                border-radius: 2px;
                background: $white;
                box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.108);
                padding: 20px 40px 25px 40px;
                margin-bottom: 15px;
                .fieldsEventContainer {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }
            }
            .eventInfoContainer:last-child {
                margin-bottom: 0;
            }
            .eventItemsInfoContainer {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .eventItemsContainer {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    max-width: 600px;
                    flex-wrap: wrap;
                    .eventItemContainer {
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 25px;
                        .headerEventItem {
                            font-weight: 600;
                            font-size: 14px;
                            margin-bottom: 30px;
                            color: $grey190;
                        }
                        .itemsListContainer {
                            display: flex;
                            flex-direction: column;
                            ul {
                                padding-left: 25px;
                                margin: 0;
                                li {
                                    font-weight: normal;
                                    font-size: 14px;
                                    color: $grey190;
                                    margin-bottom: 10px;
                                }
                                li:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }
                        .notSpecifiedMessage {
                            color: $statusSevereWarningTypeOrIcon;
                        }
                    }
                }
                .speechLengthEventContainer {
                    margin: 25px 0 15px 0;
                }
            }
            .fieldEventContainer {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                max-width: 400px;
                margin-bottom: 15px;
                flex-wrap: wrap;
                .fieldColumnName {
                    font-weight: 600;
                    font-size: 14px;
                    color: $typePrimary;
                    width: 150px;
                }
                .fieldColumnValue {
                    font-weight: normal;
                    font-size: 14px;
                    color: $typePrimary;
                    width: 250px;
                }
                .notSpecifiedMessage {
                    color: $statusSevereWarningTypeOrIcon;
                }
            }
            .fieldEventContainer:last-child {
                margin-bottom: 0;
            }
        }
        .submitButton {
            max-width: 200px;
        }
    }
}
