.fieldWrapper {
    width: 100%;
    margin-bottom: 32px;
    // @media (max-width: 640px) {
    //     margin-bottom: 20px;
    // }
}

.fieldContainer {
    width: 45%;
    max-width: 400px;
    margin-right: 20px;
    display: inline-block;
    vertical-align: top;

    @media (max-width: 640px) {
        width: 100%;
        margin-bottom: 0;
    }
}

.descriptionContainer {
    width: 45%;
    max-width: 320px;
    display: inline-block;
    padding-top: 10px;
    vertical-align: bottom;

    &.errorPadding{
        margin-bottom: 10px;
    }

    @media (max-width: 640px) {
        width: 80%;
        max-width: 100%;
        padding: 0 10% 0 0;
        margin-top: 10px;
    }
    .icon {
        // text-align: center;
        display: inline-block;
        width: 20px;
        vertical-align: top;
        font-size: 10px;
        .iconStyling {
            margin-top: 3px;
        }
    }
    .description {
        text-align: left;
        display: inline-block;
        width: calc(100% - 20px);
        font-size: 12px;
        line-height: 16px;
        color: #605e5c;
        vertical-align: top
    }
}
