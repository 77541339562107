@import "../styles/variables";

body {
    // for Teams, the default background colour is grey or black depending on theme
    background-color: #fff;
}

.App {
    width: 100%;
    color: $grey160;
    font-size: $bodyText;
    //This was added to avoid double-tapping the buttons at the bottom of the page in Safari on mobile devices. 
    //See https://benfrain.com/the-ios-safari-menu-bar-is-hostile-to-web-apps-discuss/ for more information
    @media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0) { 
        @media (max-width: 640px) {
            margin-bottom: 45px;
        }
    }
}

* {
    box-sizing: border-box;
}

label.ms-Label {
    color: $grey160;
}

//These styles have been added to prevent zoom increases for select\textarea\input fields on mobile devices.
@supports (-webkit-overflow-scrolling: touch) {
    select,
    textarea,
    input {
      font-size: 16px !important;
    }
}