.summaryFieldContainer {
    width: 100%;
    padding-bottom: 10px;
    .fieldContainer {
        padding: 5px 0;
        .fieldText {
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            color: #323130;
            @media (max-width: 640px) {
                font-size: 12px;
                line-height: 16px;
                font-weight: 700;
            }
        }
        .asterisk {
            color: #a4262c;
        }
    }
    .valueContainer {
        padding: 5px 0;
        .valueText {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: #201f1e;

            @media (max-width: 640px) {
                font-size: 12px;
                line-height: 16px;
            }

            a,
            a:visited {
                color: #2b61eb;
                cursor: pointer;
            }
        }
        .missingText {
            color: #d83b01;
        }
    }

    &.useLessPadding {
        .fieldContainer {
            padding: 0;
            .fieldText {
                line-height: 16px;
            }
        }
        .valueContainer {
            padding: 0;
            .valueText {
                line-height: 16px;
            }
        }
    }
}
