.createRecordContainer {
    margin: 0 auto;
    width: 100%;
    max-width: 1000px;
}

.createRecordSection {
    padding: 5px 10% 160px 10%;

    @media (max-width: 640px) {
        padding: 15px 5% 40px 5%;
    }
}

.formContainer {
    width: 100%;
    max-width: 1000px;
}
.createRecordFooter {
    margin-top: 64px;
    padding-top: 32px;
    min-height: 10px;
    border-top: #f3f2f1 solid 2px;
    max-width: 1000px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    .noBorder {
        border: none;
    }
    @media (max-width: 640px) {
        margin-top: 40px;
        padding-top: 20px;
    }
}
.leftButtonContainer {
    display: flex;
    gap: 16px;
    flex-flow: row;
}

.rightButtonContainer {
    display: flex;
    gap: 16px;
    flex-flow: row;
    justify-content: flex-end;
}
.errorMessage {
    width: 50%;
}

@media (max-width: 640px) {
    .leftButtonStyling {
        margin-right: 8px;
        max-width: 50%;
        white-space: nowrap;
    }
}
