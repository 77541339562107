.manageRecordNotificationsContainer {
    font-style: normal;
    font-weight: 400;

    .sectionHeader {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
    }

    .sectionContent {
        margin-top: 38px;
    }

    .sectionContent2 {
        margin-top: 46px;
    }

    .subHeader {
        font-size: 14px;
        font-weight: 600;
    }

    .primaryButtonStyling {
        margin-right: 16px;
    }

    // .buttonStyling {
    //     margin-right: 16px;
    //     vertical-align: bottom;
    //     // position: absolute;
    //     min-width: 270px;
    // }

    .myNotificationsContainer {
        padding-bottom: 48px;
        border-bottom: solid #f3f2f1 1px;
    }
    .buttonContainer {
        padding-top: 24px;
        padding-bottom: 72px;
        // border-bottom: solid #f3f2f1 2px;
    }

    // .subheader {
    //     font-size: 14px;
    //     font-weight: 600;
    // }
    .buttonSpinner {
        margin-left: 4px;
    }
}
