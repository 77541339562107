.appSettingsPage {
    width: 100%;
    margin-top: 30px;
    .pageContainer {
        width: 80%;
        margin: auto;
        .pageHeader{
            font-weight: 600;
            font-size: 28px;
            line-height: 36px;
            margin-bottom: 48px;
        }
        .bodyContainer {
            // padding-top:48px;
            padding-bottom: 64px;
            border-bottom: solid #f3f2f1 1px;
        }
        .buttonContainer {
            padding-top: 32px;
            padding-bottom: 72px;
            .primaryButtonStyling {
                margin-right: 16px;
            }
        
            .buttonSpinner {
                margin-left: 4px;
            }         
        }

    }
}