.proxiesTableContainer{
    width: calc(100% - 30px);
    background-color: #f1efee;
    padding: 15px;
    @media (max-width: 1023px) {
        width: 100%;
    }
    @media (max-width: 640px) {
        padding: 5px;
    }

    .bodyColumn {
        padding: 5px 0;
        vertical-align: middle;

        .linkContainer {
            text-decoration: none;
            .linkIcon {
                display: inline-block;
                vertical-align: bottom;
                margin-right: 5px;
                .icon {
                    vertical-align: bottom;
                }
            }
            .linkText {
                display: inline-block;
                color: #201F1E;
                vertical-align: unset;
                margin-left: 5px;
                font-size:14px;
            }
        }
    }
    .primaryText {
        color: #0052c2;
        font-size: 14px;
        font-weight: 400;
    }
    .secondaryText {
        font-size: 14px;
        color: #605e5c;
    }
    .tertiaryText {
        font-size: 14px;
        color: #605e5c;
        font-weight: 500;
    }
    .quaternaryText {
        font-size: 12px;
        color: #605e5c;
        font-weight: 500;
        margin: 10px 0;
    }
    .quinaryText {
        font-size: 12px;
        color: #323130;
        font-weight: 600;
        margin-top: 10px;
    }

    .iconContainer{
        padding-top: 5px;
        padding-left: 5px;
    }
    .icon {
        color: #0052C2;
        font-size: 16px;
    }

    // Mobile Cards
    .card {
        padding: 15px;
        margin: 5px;
        background: #ffffff;
        box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
        border-radius: 2px;
        // .pdfButton {
        //     border-color: $inputBorder;
        //     margin: 12px 0;
        //     i {
        //         color: $themePrimary;
        //     }
        // }
        // .linkStyling {
        //     text-decoration: none;
        // }
        .mobileButton {
            margin-top: 10px;
        }
        .linkContainer {
            text-decoration: none;
            display: block;
        }
    }
}