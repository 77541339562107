@import "../../../styles/variables";
.optionalText {
    display: inline;
    opacity: 0.7;
    font-size: 12px;
}

.notAllocatedText {
    .fieldTypeText {
        display: inline;
        opacity: 0.7;
        font-size: 14px;
    }
    &.required {
        color: $statusSevereWarningTypeOrIcon;
    }
}

.personDropdown {
    margin-bottom: 20px;
}

.taskDropdown {
    margin-bottom: 20px;
}

.persona {
    margin-top: 5px;
    margin-bottom: 5px;
}

.settingsIcon {
    position: absolute;
    top: 5px;
    right: 10px;
    color: #666666;
    &:hover {
        cursor: pointer;
        color: black;
    }
}

.roleContainer {
    flex: 0 0 calc(33.3333% - 16px);
    margin: 0 16px 16px 0;
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
    border-radius: 2px;
    max-width: 450px;
    .roleContent {
        width: 100%;
        padding: 16px;
        min-height: 112px;
        height: 100%;
        border: 1px solid #eee;
        position: relative;
        .roleHeader {
            margin-bottom: 15px;
            position: relative;
            padding-right: 50px;
            .roleNameText {
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                display: inline-block;
            }
            .toolTip {
                cursor: pointer;
                display: inline-block;
            }
            .infoIcon {
                margin-left: 5px;
                font-size: 12px;
                display: inline-block;
                cursor: help;
                vertical-align: text-top;
            }
            .addIconContainer {
                position: absolute;
                top: 0;
                right: 0;
                &:hover {
                    cursor: pointer;
                }
                .addIconText {
                    display: inline-block;
                    font-size: 14px;
                    line-height: 20px;
                    vertical-align: middle;
                }
                .addIcon {
                    display: inline-block;
                    color: $themePrimary;
                    font-size: 14px;
                    margin-right: 8px;
                    vertical-align: middle;
                }
            }
        }
        .persona {
            margin-bottom: 8px;
            margin-top: 8px;
        }
    }
    .roleMandatoryBackground {
        background-color: #ffffff;
    }
    .roleOptionalBackground {
        background-color: $grey20;
    }
}

@media (max-width: 640px) {
    .roleContainer {
        margin: 0;
        margin-bottom: 16px;
    }
}

.roleForm {
    .subTitle {
        font-size: 16px;
        font-weight: 600;
    }
    .unAssignSection {
        >* {
            margin: 16px 0px;
        }
    }
    .changePersonSection {
        >* {
            margin: 16px 0px;
        }
        .searchPeople {
            margin-bottom: 40px;
        }
        .reasonChange {
            width: 100%;
        }
        .whoComments {
            margin-top: 10px;
            cursor: help;
        }
        .iconStyling {
            font-size: 14px;
            vertical-align: middle;
            margin-left: 5px;
            cursor: help;
        }
    }
}