.collapsibleContainer {
     :global {
        .head {
            width: 100%;
            border-top: #f3f2f1 solid 2px;
            padding: 11.5px 0;
            @media (max-width: 640px) {
                width: calc(100% - 40px);
                margin: auto;
                border-top: none;
                border-bottom: #f3f2f1 solid 2px;
            }
            &.open {
                border-bottom: #f3f2f1 solid 2px;
                @media (max-width: 640px) {
                    border-bottom: white solid 2px;
                }
            }
            .icon {
                display: inline-block;
                width: 70px;
                text-align: center;
                cursor: pointer;
                color: #605e5c;
                font-size: 18px;
                .chevron {
                    font-size: 16px;
                    @media (max-width: 640px) {
                        font-size: 14px;
                    }
                }
                @media (max-width: 640px) {
                    width: 30px;
                    font-size: 14px;
                    margin-top: 6px;
                }
            }
            .title {
                display: inline-block;
                width: calc(100% - 125px);
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                vertical-align: top;
                color: #201f1e;
                cursor: pointer;
                white-space: nowrap;
                &.open {
                    font-weight: 600;
                }
                &.titleLarge {
                    font-size: 18px;
                }
                &.titleSmall {
                    font-size: 14px;
                }
                @media (max-width: 640px) {
                    margin-left: 18px;
                }
            }
            .edit {
                display: inline-block;
                width: 50px;
                text-align: right;
                vertical-align: middle;
                cursor: pointer;
                color: #201f1e;
                font-size: 12px;
                padding-bottom: 3px;
                .editIcon {
                    margin-right: 10px;
                }
            }
            &.highlighted {
                background-color: #edebe9;
            }
        }
        .body {
            width: 100%;
            &.highlighted {
                background-color: #faf9f8;
            }
            &.expanded {
                height: 100%;
                padding: 20px 20px;
                @media (max-width: 640px) {
                    padding: 1px 20px 20px;
                }
            }
            &.collapsed {
                height: 0;
                overflow: hidden;
            }
        }
    }
    .collapsibleSection {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}