@import "../../styles/variables";

.recordTypeColumnIcon {
    font-size: 17px;
    margin-left: 3px;
}
.recordFieldColumn {
    font-size: 14px;
}
.recordFieldValue {
    color: $grey160;
}
// columns
.recordIdColumn {
    cursor: pointer;
}
.iconColumnContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    .iconColumnIcon {
        font-size: 16px;
        text-align: center;
        margin-bottom: 3px;
    }
    .iconColumnText {
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
    }
}
.recordLink {
    text-decoration: none;
    outline: none;
}