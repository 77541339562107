.recordItem{
    display: flex;
    gap: 30px;
    .recordLink{
        text-decoration: none;
        outline: none;
    }
    .recordDoc{
        display: flex;
        min-width: 150px;
        .iconColumnIcon {
            font-size: 16px;
            text-align: center;
            margin-bottom: 3px;
        }
        .iconColumnText {
            font-weight: 600;
            font-size: 10px;
            line-height: 12px;
        }
    }
    .recordInformation{
        display: flex;
        flex-direction: column;
        gap: 10px;
        .recordTitle{
            color: #605E5C;
        }
        .stageSection{
            line-height: 20px;
            font-weight: 600;
            .value{
                margin-left: 5px;
                color: #605E5C
            }
        }
        .modified{
            color: #605E5C;
        }
    }
}

.mobileRecordItem{
    gap: 8px;
    flex-direction: column;
}