@import "../../../styles/variables";
.formContainer {
    min-width: 650px;
    @media (max-width: 640px) {
        width: auto;
        min-width: auto;
    }
    .descriptionForm {
        font-size: 14px;
        line-height: 20px;
        color: $grey160;
        max-width: 65%;
        margin: 40px 0;
    }
    .documentsSection {
        .documentsSectionHeader {
            font-weight: 600;
            font-size: 16px;
            color: $grey160;
            margin: 25px 0;
        }
        .documentsContainer {
            display: flex;
            flex-direction: column;
            .documentItem {
                display: flex;
                flex-direction: column;
                min-height: 30px;
                margin-bottom: 15px;
                .documentItemCheckboxContainer {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    .iconColumnContainer {
                        display: flex;
                        flex-direction: row;
                        width: 50%;
                        .iconColumnIcon {
                            font-size: 16px;
                            text-align: center;
                            margin-bottom: 3px;
                            margin-right: 10px;
                            color: $themeDark;
                        }
                        .iconColumnText {
                            font-size: 14px;
                            line-height: 20px;
                            color: $grey190;
                        }
                    }
                    .checkboxContainer {
                        width: 50%;
                        display: flex;
                        justify-content: flex-end;
                    }
                }
                .textFieldInstructions {
                    margin-top: 20px;
                }
            }
            .documentItem:last-child {
                margin-bottom: 0;
            }
        }
    }
}
