.manageRecordContainer {
    width: 80%;
    margin: auto;
    @media (max-width: 640px) {
        width: calc(100% - 40px);
    }

    .recordHeaderContainer{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 20px;
        .buttons {
            display: flex;
            min-width: 340px;
            justify-content: space-between;
        }
        .recordTitle {
            font-weight: 600;
            font-style: normal;
            font-size: 28px;
            line-height: 36px;
            margin: 0 0 24px;
        }
    }
    .recordHeaderContainerMobile{
        flex-direction: column-reverse;
        flex-wrap: wrap;
        padding-top: 0px;
        .buttons{
            flex-wrap: wrap;
            gap: 10px;
            margin-bottom: 30px;
            min-width: auto;
        }
        .recordTitle {
            margin-bottom: 24px;
            font-size: 20px;
            line-height: 28px;
        }
        .items{
            padding-bottom: 10px;
        }
    }
    .recordIdContainer {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #2b61eb;
        height: 20px;
    }

    .recordId {
        margin-left: 4px;
    }
    .icon {
        height: 20px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
    }
    .recordType {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        margin: 4px 0 8px;
    }
    .recordResponseBy {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
    }
    .recordRequestedBy{
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        padding-top: 4px;
    }
    .securityAccess{
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        padding-top: 4px;
    }
}
.noResultMessage {
    padding: 20px 10% 0;
}
