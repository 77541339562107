@import "../../styles/variables";

.headerContainer {
    margin-bottom: 60px;
}
.formContainer {
    width: 100%;
}
.signatureContainer {
    border-top: #f3f2f1 solid 2px;
    margin-top: 30px;
    padding-top: 10px;
}
.checkBoxesEventContainer {
    display: flex;
    flex-direction: column;
    .checkBoxesHeader {
        font-weight: 600;
        font-size: 14px;
        color: $grey190;
        margin-bottom: 20px;
    }
    .checkBoxesContainer {
        display: flex;
        flex-direction: column;
        div[class^="ms-Checkbox"] {
            margin-bottom: 10px;
        }
        div[class^="ms-Checkbox"]:last-child {
            margin-bottom: 0;
        }
    }
}

