.manageRelatedRecordsPage{
    .top{
        display: block;
    }
    .mid{
        display: block;
        h2 {
            font-weight: 600;
            line-height: 28px;
            margin: 0px;
        }
        .allrecordsTableContainer{
            h2{
                margin: 24px 0px 48px;
            }
            .searchBoxContainer{
                display: flex;
                gap: 16px;
                margin-bottom: 50px;
                .searchBox{
                    min-width: 250px;
                    max-width: 300px;
                    i {
                        color: #0052c2;
                    }
                    @media (max-width: 640px) {
                        margin-top: 5px;
                    }
                }
                .resultsCount {
                    line-height: 32px;
                }
            }
            .addSelectedRecordsBtnContainer{
                padding: 48px 0 64px;
            }
        }
        .relatedRecordsTable{
            h2{
                margin: 32px 0px 34px;
            }
            .removeSelectedRecordsBtnContainer{
                padding: 48px 0 64px;
            }
        }
    }
    .bot{
        display: block;
        padding-top: 48px;
        padding-bottom: 100px;

        .buttonsArea{
            display: flex;
            gap: 16px;
            .spinner{
                margin-left: 8px;
            }
            @media (max-width: 640px){
                :global{
                    .ms-Button--primary{
                        height: auto;
                    }
                }
            }
        }
    }
}
.layoutPaddings{
    width: 100%;
    padding: 0 10%;
}
.separator {
    padding: 0px;
    line-height: 1px;
    height: 1px;
}