@import "../../../styles/variables";

.contactContainer {
    width: 100%;
    position: relative;
    word-break: break-word;
    .gap{
        margin-top: 16px;
    }
    .normalText {
        font-size: 14px;
        color: $grey160;
    }
    .smallText {
        font-size: 12px;
        color: $grey130;
    }
    .row{
        display: flex;
        flex-wrap: wrap;
        .title{
            margin-right: 4px;
        }
        a.value{
                color: $themePrimary;
                text-decoration: none;
            
        }
    }
    .orgViewContainer {
        display: flex;
        flex-direction: column;
        .headerTitle {
            font-size: 14px;
            color: $grey160;
            font-weight: 600;
        }
    }
    .userContact {
        display: flex;
        flex-direction: row;
        margin-top: 16px;
        div {
            font-size: 14px;
            color: $grey160;
        }
        span {
            font-size: 14px;
            color: $themePrimary;
            margin-left: 3px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
