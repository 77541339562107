@import "../../styles/variables";
@import "./ManageRecordCommon.module";
.manageRecordDetailsContainer {
    .footer {
        width: 100%;
        button {
            margin: 25px 0;
        }
    }
    .formContainer {
        .replyTypeWarning{
            width: 45%;
            max-width: 380px;
        }
    }
    .btnsContainer {
        margin: 10px 0;
        display: flex;
        gap: 10px;
        .updateBtn {
            .text,
            .spinner {
                margin: 0px 4px;
            }
        }
    }
    .checkBoxesEventContainer {
        display: flex;
        flex-direction: column;
        .checkBoxesHeader {
            font-weight: 600;
            font-size: 14px;
            color: $grey190;
            margin-bottom: 20px;
        }
        .checkBoxesContainer {
            display: flex;
            flex-direction: column;
            div[class^="ms-Checkbox"] {
                margin-bottom: 10px;
            }
            div[class^="ms-Checkbox"]:last-child {
                margin-bottom: 0;
            }
        }
    }
}