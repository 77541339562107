.templateListContainer {
    width: 100%;
    font-size: 14px;
    font-weight: normal;

    .iconBlue {
        color: #005a9e;
    }

    .selectedTemplate {
        background-color: white;
        padding: 5px 5px;
        display: flex;
        border-bottom: solid 1px #edebe9;
    }

    .selectedTemplate:last-child {
        background-color: white;
        padding: 5px 5px;
        display: flex;
        border-bottom: none;
    }

    .selectedTemplateContainer {
        flex-basis: 70%;
        padding: 5px 0px;
        word-break: break-all;
    }

    .removeButtonContainer {
        flex-basis: 30%;
        text-align: right;
        
        .buttonStyling {
            // float: right;
            border-style: none;
            font-weight: normal !important;
        }
    }

    .templateNameStyle {
        text-decoration: none;
        color: #201f1e;
    }

}
