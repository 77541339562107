.expandedHeader {
    margin: 60px 0 30px 0;
    @media (max-width: 640px) {
        margin: 12px 0 0;
    }
    .expandedHeaderText {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        margin: 9px 0 16px;
    }
}