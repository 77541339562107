.siteItemContainer {
    &:hover {
        cursor: pointer;
        background-color: rgba(0,0,0,0.1);
        border-radius: 4px;
    }

    .displayName {
        line-height: 20px;
    }
    
    .modifiedText {
        color: #323130;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
    }
}

.dialogContainer {
    .modalTitle {
        & :global {
            .ms-Dialog-title {
                padding: 0 0 10px 0;
            }
        }
    }
    .breadcrumbContainer {
        align-items: baseline;
    }
    .allSitesText {
        font-size: 18px;
        color: #605e5c;
        margin: 11px 0px 1px;
        line-height: 36px;
        padding: 0px 8px;
    }
    .infoIcon {
        margin-left: 5px;
        font-size: 12px;
        display: inline-block;
        cursor: help;
        vertical-align: text-top;
    }
}

.selectedFile {
    background: rgba(0,0,0,0.1) !important;
}