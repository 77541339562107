@import "../../styles/variables";

.copyingRecordContainer {
    margin-top: 40px;
    .currentRecordInfoContainer {
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;
        .iconColumnContainer {
            display: flex;
            flex-direction: row;
            .iconColumnIcon {
                font-size: 16px;
                margin-right: 3px;
            }
            .iconColumnText {
                font-size: 14px;
            }
        }
        .recordTitle {
            margin-top: 5px;
            font-weight: 600;
            font-size: 16px;
        }
    }

    .beforeActionDescrContainer {
        display: flex;
        flex-direction: column;
        .actionHeaderDescr {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
        }
        .generalDescr {
            font-size: 14px;
            line-height: 20px;
            max-width: 700px;
        }
    }

    .choiceGroupContainer {
        margin: 45px 0 20px 0;
        .subChoiceLabel {
            color: $grey130;
            font-size: 12px;
            margin-bottom: 25px;
            margin-left: 25px;
        }
    }
    .recordID {
        width: 100%;
        color: #2b61eb;
        .icon {
            margin-right: 5px;
        }
    }
    .description {
        font-size: 14px;
        line-height: 20px;
        margin: 25px 0;
        max-width: 620px;
    }
    .actionButtonsContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 25px;
        .actionButton {
            margin-right: 15px;
        }
    }
}
