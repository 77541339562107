@import "../../../styles/variables";

.fileListContainer {
    width: 100%;
    font-size: 14px;
    font-weight: normal;
    padding: 16px 15px;

    .iconBlue {
        color: #005a9e;
    }

    .iconGrey {
        color: #605E5C;
    }

    .selectedFile {
        background-color: white;
        padding: 5px 5px;
        display: flex;
        border-bottom: solid 1px #edebe9;
        @media (max-width: 1023px) {
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        
        .reorderContainer {
            display: flex;
            flex-direction: row;
            padding: 0px 10px;
            align-items: center;
            justify-content: space-between;
            min-width: 65px;
            max-width: 115px;
            @media (max-width: 1023px) {
                display: none;
            }
            .reorder {
                .chevron {
                    font-size: 16px;
                }
            }
        }

        .selectedFilesContainer {
            flex-basis: 50%;
            line-height: 24px;
            padding: 5px 10px;
            word-break: break-all;
            @media (max-width: 1023px) {
                flex-basis: 80%;
            }
            
            .fileNameStyle {
                text-decoration: none;
                color: $themePrimary;
                font-size: 14px;
                margin-left: 10flexpx;
            }

            .fileSignedIcon {
                width: 20px;
                height: 20px;
                display: inline-block;
                @media (max-width: 1023px) {
                    display: none;
                }
                .editIcon {
                    font-size: 16px;
                    color: #005a9e;
                }
            }
        }

        .buttonContainer {
            flex-basis: 50%;
            display: flex;
            justify-content: flex-end;
            @media (max-width: 1023px) {
                display: none;
            }
            .buttonStyling {
                padding: 0px 10px;
                border: none;
                span[class^="ms-Button-label label-"] {
                    font-weight: normal;
                }
            }
        }
        .buttonContainerMobile {
            flex-basis: 20%;
            .menuButton {
                margin: auto;
                padding: 3px 0 0;
                width: 32px;
                height: 32px;
                cursor: pointer;
                text-align: center;

                &:hover,:active {
                    background-color: #EDEBE9;
                }
            }
            @media (min-width: 1024px) {
                display: none;
            }
        }
    }

    .selectedFile:last-child {
        border-bottom: none;
    }

    .selectedFile:first-child .reorder:first-child,
    .selectedFile:last-child .reorder:last-child {
        cursor: not-allowed;
    }

}
.errorMessage {
    font-size: 12px;
    color: #a4262c;
}

.panelBlade {
    width: 100%;
    height: 30px;
    font-size: 16px;
    margin-top: 10px;
    .iconContainer {
        min-width: 25px;
        display: inline-block;
        .iconBlue {
            color: #005a9e;
        }
    }
    .textContainer {
        display: inline-block;
    }
}