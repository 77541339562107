@import "../../styles/variables";
.confirmationContainer {
    .recordID {
        width: 100%;
        color: #2b61eb;
        .icon {
            margin-right: 5px;
        }
    }
    .recordIds {
        margin-top: -30px;
    }
    .description {
        font-size: 14px;
        line-height: 20px;
        margin-top: 24px;
        max-width: 620px;
    }
}
